import Translations from 'i18n/translation';
import { MouseEventHandler } from 'react';
import { Button } from 'react-bootstrap';
import { Pencil } from 'react-bootstrap-icons';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';

export interface UpdateButtonProps {
    onClick?: MouseEventHandler;
    className?: string;
}

export function UpdateIconButton({ onClick }: UpdateButtonProps) {
    return (
        <div onClick={onClick} className="d-flex btn-edit align-items-center justify-content-center btn btn-primary btn-icon btn-sm rounded-circle me-2 ">
            <Pencil />
        </div>
    );
}

export function UpdateButton({ onClick, className }: UpdateButtonProps) {
    const { t } = useTranslation();
    return (
        <Button onClick={onClick} variant="primary" size="sm" className={'d-flex  rounded btn btn-info btn-sm ' + className}>
            <span className="btn-inner me-2">
                <Pencil />
            </span>
            <div>{t(Translations.button.update)}</div>
        </Button>
    );
}
export function UpdateButtonNavigate({ url }: { url: string; onClick?: MouseEventHandler }) {
    const { t } = useTranslation();
    const navigate = useNavigate();
    return (
        <Button onClick={() => navigate(url)} variant="success" size="sm" className="d-flex flex-row align-items-center rounded m-1">
            <span className="btn-inner me-2">
                <Pencil />
            </span>
            <div>{t(Translations.button.update)}</div>
        </Button>
    );
}
