import { yupResolver } from '@hookform/resolvers/yup';
import { FileResponse } from 'api/models';
import documentApi from 'api/v2/document/documentApi';
import { DocumentRequest } from 'api/v2/document/model/document.model';
import { SaveButton } from 'components/Button';
import { CompanySelect } from 'components/Common';
import { InputField, UploadImageField } from 'components/FormControl';
import DateTimeField from 'components/FormControl/DateTimeField';
import UploadFileField from 'components/FormControl/UploadFileField';
import { ModalHookComponentProps } from 'components/ModalHook';
import { UploadImage } from 'components/shared';
import { error } from 'console';
import { FileHelper } from 'helper/file.helper';
import { lang } from 'i18n/lang';
import Translations from 'i18n/translation';
import React, { useState } from 'react';
import { Button, Form, FormCheck } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import * as yup from 'yup';
import DocGroupSelect from './docGroupSelect';

interface DocumentUploadProps extends ModalHookComponentProps {}

const DocumentUpload: React.FC<DocumentUploadProps> = ({ onClose, onCompleted }) => {
    const schema = yup.object().shape({
        tag: yup.string().required(),
        approvedDate: yup.date().notRequired(),
        version: yup.string().required(),
        groupId: yup.string().required(),
        filePath: yup.string().notRequired(),
        companyId: yup.string().notRequired(),
        link: yup.string().notRequired(),
        name: yup.string().required(),
    });
    const [fileResponse, setFileResponse] = React.useState<FileResponse | null>(null);
    const [companyId, setCompanyId] = useState<string>('');
    const onUploaded = (value: FileResponse) => {
        setFileResponse(value);
    };

    const {
        handleSubmit,
        control,
        getValues,
        reset,
        formState: { errors },
    } = useForm({ resolver: yupResolver(schema) });

    const onSubmit = () => {
        var form = getValues();
        console.log(fileResponse, form.link);
        if (!fileResponse && !form.link) {
            toast.error('Vui lòng chọn file cần tải lên hoặc nhập liên kết tài liệu');
            return;
        }
        const payload: DocumentRequest = {
            id: null,
            tag: form.tag,
            link: form.link,
            approvedDate: form.approvedDate,
            version: form.version,
            companyId: companyId,
            file: fileResponse,
            groupId: form.groupId,
            name: form.name,
        };

        documentApi
            .create(payload)
            .then((res) => {
                // reset({});
                // setFileResponse(null);
            })
            .catch((err) => {});
    };
    return (
        <div>
            <form onSubmit={handleSubmit(onSubmit)} onClick={() => console.log(errors)}>
                <div className="mb-3 row">
                    <UploadFileField md={6} name="filePath" control={control} onUploaded={onUploaded} />
                    <InputField md={6} name="name" control={control} label={lang.text(Translations.document.name)} required />
                    <CompanySelect md={6} name="companyId" control={control} required={false} disable={false} readOnly={false} onCompanyChange={setCompanyId} />
                    <DateTimeField md={6} name="approvedDate" control={control} label={lang.text(Translations.document.approvedDate)} required />
                    <InputField md={6} name="version" control={control} label={lang.text(Translations.document.version)} required />
                    <DocGroupSelect isCanCreate isMulti={false} md={12} name="groupId" control={control} required />
                    <InputField md={12} name="link" control={control} label={lang.text(Translations.document.link)} />
                    <InputField name="tag" control={control} label={lang.text(Translations.document.tag)} />
                    <div className="d-flex justify-content-end">
                        <SaveButton type="submit" />
                    </div>
                </div>
            </form>
        </div>
    );
};
// id: string | null;
// size: number;
// fileType: FileType;
// tag: string;
// link: string;
// approvedDate: string;
// version: string;
// companyId: string;

export default DocumentUpload;
