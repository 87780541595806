import { DEFAULT_CONFIG } from 'choices.js';
import * as React from 'react';
import { InputHTMLAttributes } from 'react';
import { Form, FormControlProps } from 'react-bootstrap';
import { Control, useController } from 'react-hook-form';
import { BaseFormControlProps } from '../baseFormProps';

export interface InputFieldProps extends BaseFormControlProps {
    placeholder?: string;
    type?: string | 'number' | 'text' | 'password' | 'email' | 'date';
    onValueChange?: (value: string) => void;
    min?: number;
    max?: number;
    className?: string;
}

export function InputField({
    name,
    control,
    label,
    placeholder,
    type,
    formGroupClasses,
    formControlClasses,
    disabled = false,
    defaultValue,
    required = false,
    md,
    readOnly,
    min,
    max,
    className,
    onValueChange,
}: InputFieldProps) {
    const placeholderProp = placeholder;
    const typeProp = type ? type : 'text';
    const [curentValue, setCurrentValue] = React.useState();

    const {
        field: { value, onChange, onBlur },
        fieldState: { invalid, error },
    } = useController({
        name,
        control,
        rules: required ? { required: 'This field is required' } : {},
    });
    React.useEffect(() => {
        onChange(defaultValue);
    }, [defaultValue]);

    React.useEffect(() => {
        if (curentValue !== value) setCurrentValue(value);
        if (onValueChange) onValueChange(value);
    }, [value]);
    const inValidClass = error?.message ? 'is-invalid' : '';

    return (
        <>
            <div className={`col-md-${md} ${className}`}>
                <Form.Group className={`mb-3 ${formGroupClasses}`}>
                    {label && (
                        <Form.Label htmlFor={name} className="">
                            {label} {required && <span className="text-danger">*</span>}
                        </Form.Label>
                    )}
                    <Form.Control
                        min={min}
                        max={max}
                        type={typeProp}
                        placeholder={placeholderProp}
                        name={name}
                        onChange={(e) => {
                            onChange(e.target.value);
                        }}
                        onBlur={onBlur}
                        className={`${inValidClass} ${formControlClasses}`}
                        disabled={disabled}
                        value={value ?? ''}
                        readOnly={readOnly}
                    />
                    <div className="invalid-feedback d-blocksecond d-block ">{error?.message}</div>
                </Form.Group>
            </div>
        </>
    );
}
