import Translations from 'i18n/translation';
import { t } from 'i18next';
import { MouseEventHandler } from 'react';
import { Button } from 'react-bootstrap';
import { Trash } from 'react-bootstrap-icons';
import { BaseButtonProps } from './BaseButton.props';

export function DeleteIconButton({ onClick }: BaseButtonProps) {
    return (
        <div
            onClick={onClick}
            className="d-flex btn-delete align-items-center justify-content-center btn btn-danger btn-icon btn-sm rounded-circle"
        >
            <Trash />
        </div>
    );
}

export function DeleteButton(props: BaseButtonProps) {
    return (
        <Button
            {...props}
            onClick={props.onClick}
            variant="danger"
            size="sm"
            className="d-flex  rounded btn btn-danger btn-sm"
        >
            <span className={`btn-inner ${props.text ?? 'me-2'}`}>
                <Trash />
            </span>
            {props.text ?? <div>{t(Translations.button.delete)}</div>}
        </Button>
    );
}
