import React, { InputHTMLAttributes, useEffect } from 'react';
import { Form } from 'react-bootstrap';
import { Control, useController } from 'react-hook-form';

export interface TextAreaFieldProps {
    onValueChange?: (value: string) => void;
    defaultValue?: string | number | null | undefined;
    placeholder?: string;
    control: Control<any>;
    name: string;
    defaultValues?: any;
    errorMessage?: string;
    required?: boolean;
    md?: 3 | 4 | 12 | 6 | 10;
    disable?: boolean;
    readOnly?: boolean;
    formGroupClasses?: string;
    formControlClasses?: string;
    disabled?: boolean;
    label?: string;
}

{
    /* <TextAreaField
name="note"
control={control}
label={t(Translations.form.riskLevel.note)}
placeholder={t(Translations.form.riskLevel.note)}
/> */
}
export function TextAreaField({
    name,
    control,
    label,
    placeholder,
    formGroupClasses,
    formControlClasses,
    disabled = false,
    required = false,
    defaultValue = undefined,
    onValueChange,
    md,
    readOnly,
}: TextAreaFieldProps) {
    const placeholderProp = placeholder ? placeholder : '';
    const {
        field: { value, onChange, onBlur },
        fieldState: { invalid, error },
    } = useController({
        name,
        control,
    });

    const [isFirstTime, setIsFirstTime] = React.useState<boolean>(true);
    // const [curentValue, setCurrentValue] = React.useState();
    useEffect(() => {
        if (isFirstTime && defaultValue) {
            onChange(defaultValue);
            setIsFirstTime(false);
        }
    }, [defaultValue]);
    React.useEffect(() => {
        // if (curentValue != value) {
        //     // setCurrentValue(value);
        //     onValueChange && onValueChange(value);
        // }
        if (onValueChange) onValueChange(value);
    }, [value]);
    // React.useEffect(() => {
    //     if (curentValue != value) {
    //         onChange(value);
    //     }
    // }, [curentValue]);

    const inValidClass = error?.message ? 'is-invalid' : '';

    return (
        <div className={'col-md-' + md}>
            <Form.Group className={`mb-3 ${formGroupClasses}`}>
                {label && (
                    <Form.Label htmlFor={name} className="">
                        {label} {required && <span className="text-danger">*</span>}
                    </Form.Label>
                )}
                <Form.Control
                    readOnly={readOnly}
                    name={name}
                    as="textarea"
                    rows={3}
                    type={'text'}
                    placeholder={placeholderProp}
                    value={value}
                    onChange={(e) => {
                        onChange(e.target.value);
                    }}
                    onBlur={onBlur}
                    className={`${inValidClass} ${formControlClasses}`}
                    disabled={disabled}
                />
                <div className="invalid-feedback">{error?.message}</div>
            </Form.Group>
        </div>
    );
}
