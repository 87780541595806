import { AccordionItemChildProps, MenuGroupProps } from 'components/builder/verticalNavGroup';
import {
    Bell,
    BriefCase,
    Calendar,
    Call,
    ChartSquareBar,
    CircleDirection,
    ExclamationTriangle,
    Eye,
    File,
    Folder,
    Search,
    Setting,
    ShieldCheck,
    Timer,
    Video,
    ViewGrid,
} from 'assets/icons/double-tone';
import Translations from 'i18n/translation';
import Routers from './routers';
import Roles from 'router/const/roles';
import {  Scale, SearchCircleOutline } from 'heroicons-react';
import { Analytics } from 'assets/icons/solid';

export const appMenus: MenuGroupProps[] = [{
        groupIcon: <File />,
        active: 'false',
        groupName: '',
        key: 'none',
        items: [
            {
                roles: [],
                ignorePms: true,
                path: '/',
                title: Translations.pageName.home,
                icon: <File />,
                isDisplayVerticalNav: false,
            },
        ],
    },
    {
        groupIcon: <File />,
        active: 'false',
        groupName: 'profile',
        key: 'none',
        items: [
           
            {
                roles: [],
                ignorePms: true,
                path: Routers.dashboard.root,
                title: Translations.common.changePassword,
                icon: <File />,
                isDisplayVerticalNav: false,
            },
            {
                roles: [],
                ignorePms: true,
                path: Routers.auth.changePassword,
                title:  Translations.common.changePassword,
                icon: <File />,
                isDisplayVerticalNav: false,
            },
            {
                ignorePms: true,
                roles: [],
                path: Routers.user.profile,
                title: '',
                isDisplayVerticalNav: false,
            },
        ],
    },
    {
        groupIcon: <File />,
        active: 'false',
        groupName: Translations.sidebar.safetyReport.name,
        key: 'sor',
        items: [
            {
                roles: Roles.sor.create,
                path: Routers.sor.create,
                title: Translations.sidebar.safetyReport.create,
                icon: <File />,
                isDisplayVerticalNav: true,
            },
            {
                roles: Roles.sor.view,
                path: Routers.sor.root,
                title: Translations.sidebar.safetyReport.detail,
                icon: <File />,
                isDisplayVerticalNav: true,
            },
            {
                roles: Roles.sor.synthesis_view,
                path: Routers.sor.dashboard,
                title: Translations.sidebar.safetyReport.synthesis,
                isDisplayVerticalNav: true,
            },
            {
                roles: Roles.sor.view,
                path: Routers.sor.category,
                title: Translations.sidebar.safetyReport.topic,
                isDisplayVerticalNav: true,
            },
        ],
    },
    //SEC
    {
        groupIcon: <ViewGrid />,
        active: 'false',
        groupName: Translations.sec.menuBar,
        key: 'sec',
        items: [
            {
                roles: Roles.sec.security,
                path: Routers.sec.registerDetail,
                title: Translations.sec.gate,
                icon: <File />,
                isDisplayVerticalNav: false,
            },
            {
                roles: Roles.sec.admin,
                path: Routers.sec.gate,
                title: Translations.sec.gate,
                icon: <File />,
                isDisplayVerticalNav: true,
            },
            {
                roles: Roles.sec.admin,
                path: Routers.sec.register,
                title: Translations.sec.register,
                isDisplayVerticalNav: true,
            },

            {
                roles: Roles.sec.admin,
                path: Routers.sec.report,
                title: Translations.sec.report,
                isDisplayVerticalNav: true,
            },
        ],
    },
    /* Safety Inspections */
    {
        groupIcon: <SearchCircleOutline />,
        active: 'false',
        groupName: Translations.sidebar.safetyInspections.name,
        key: 'si',
        items: [
            {
                roles: Roles.si.create,
                path: Routers.safetyInspections.report.create,
                title: Translations.sidebar.safetyInspections.daily.create,
                isDisplayVerticalNav: true,
            },
            {
                roles: Roles.safety_periodically_report.view,
                path: Routers.safetyInspections.report.root,
                title: Translations.sidebar.safetyInspections.daily.detail,
                isDisplayVerticalNav: true,
            },
            {
                roles: Roles.si.view,
                path: Routers.safetyInspections.report.dashboard,
                title: Translations.sidebar.safetyInspections.daily.synthesis,
                isDisplayVerticalNav: true,
            },
            {
                roles: Roles.si.crud_template,
                path: Routers.safetyInspections.template.root,
                title: Translations.sidebar.safetyInspections.daily.group,
                isDisplayVerticalNav: true,
            },
        ],
    },
    /* Equipment  */
    {
        groupIcon: <BriefCase />,
        active: 'false',
        groupName: Translations.sidebar.equipment.name,
        key: 'equipment',
        items: [
            {
                roles: Roles.equipment.view,
                path: Routers.equipment.detail,
                title: '',
                isDisplayVerticalNav: false,
            },
            {
                roles: [...Roles.equipment_report.view_daily, ...Roles.equipment_report.view_periodically],
                path: Routers.equipment.reportDetail,
                title: '',
                isDisplayVerticalNav: false,
            },
            {
                roles: Roles.equipment_report.templateView,
                path: Routers.equipment.checkListDetail,
                title: '',
                isDisplayVerticalNav: false,
            },
            {
                roles: Roles.equipment.crud,
                path: Routers.equipment.reportCreate,
                title: Translations.sidebar.equipment.create,
                isDisplayVerticalNav: true,
            },
            {
                roles: Roles.equipment.view,
                path: Routers.equipment.checkList,
                title: Translations.sidebar.equipment.checkList,
                isDisplayVerticalNav: true,
            },
            {
                roles: Roles.equipment.view,
                path: Routers.equipment.list,
                title: Translations.sidebar.equipment.list,
                isDisplayVerticalNav: true,
            },
            {
                roles: Roles.equipment.view,
                path: Routers.equipment.report,
                title: Translations.sidebar.equipment.report,
                isDisplayVerticalNav: true,
            },
            {
                roles: Roles.equipment.crud,
                path: Routers.equipment.type,
                title: Translations.sidebar.equipment.type,
                isDisplayVerticalNav: true,
            },
            {
                roles: Roles.equipment.crud,
                path: Routers.equipment.create,
                title: Translations.common.addNew,
                isDisplayVerticalNav: false,
            },
        ],
    },
    /* Trip  */
    // {
    //     groupIcon: <CircleDirection />,
    //     active: 'false',
    //     groupName: Translations.sidebar.trip.name,
    //     key: 'trip',
    //     items: [
    //         {
    //             roles: Roles.trip.default,
    //             path: Routers.trip.root,
    //             title: Translations.sidebar.trip.trip,
    //         },
    //         {
    //             roles: Roles.trip.default,
    //             path: Routers.trip.report,
    //             title: Translations.sidebar.trip.report,
    //         },
    //     ],
    // },
    /* Kpis  */
    {
        groupIcon: <Analytics />,
        active: 'false',
        groupName: Translations.sidebar.kpi.name,
        key: 'kpis',
        items: [
            {
                roles: Roles.kpi_report.crud_report,
                path: Routers.kpi.create,
                title: Translations.sidebar.kpi.create,
                isDisplayVerticalNav: true,
            },
            {
                roles: Roles.kpi_report.crud_template,
                path: Routers.kpi.assignment,
                title: Translations.sidebar.kpi.assignment,
                isDisplayVerticalNav: true,
            },
            {
                roles: Roles.kpi_report.crud_template,
                path: Routers.kpi.type,
                title: Translations.sidebar.kpi.type,
                isDisplayVerticalNav: true,
            },
        ],
    },
    /* work performance */
    {
        groupIcon: <ChartSquareBar />,
        active: 'false',
        groupName: Translations.sidebar.wpr.name,
        key: 'WPM',
        items: [
            {
                roles: Roles.ppr_report.crud_report,
                path: Routers.wpr.pprCreate,
                title: Translations.sidebar.wpr.pprCreate,
                isDisplayVerticalNav: true,
            },
            {
                roles: Roles.ppr_report.view_report,
                path: Routers.wpr.root,
                title: Translations.sidebar.wpr.pprDetail,
                isDisplayVerticalNav: true,
            },
            {
                roles: Roles.ppr_report.view_category,
                path: Routers.wpr.levelPPR,
                title: Translations.sidebar.wpr.levelPPR,
                isDisplayVerticalNav: true,
            },
            {
                roles: Roles.skpi.create,
                path: Routers.wpr.skpiCreate,
                title: Translations.sidebar.wpr.skpiCreate,
                isDisplayVerticalNav: true,
            },
            {
                roles: Roles.skpi.view,
                path: Routers.wpr.skpiReview,
                title: Translations.sidebar.wpr.skpiReview,
                isDisplayVerticalNav: true,
            },
        ],
    },
    /* training online */
    // {
    //     groupIcon: <Video />,
    //     active: 'false',
    //     groupName: Translations.sidebar.trainingOnline.name,
    //     key: 'training',
    //     items: [
    //         {
    //             roles: Roles.training.view,
    //             path: Routers.trainingOnline.course,
    //             title: Translations.sidebar.trainingOnline.course,
    //         },
    //         {
    //             roles: Roles.training.edit,
    //             path: Routers.trainingOnline.category,
    //             title: Translations.sidebar.trainingOnline.category,
    //         },
    //         {
    //             roles: Roles.training.sp,
    //             path: Routers.trainingOnline.myCourse,
    //             title: Translations.sidebar.trainingOnline.myCourse,
    //         },
    //         {
    //             roles: Roles.training.sp,
    //             path: Routers.trainingOnline.scores,
    //             title: Translations.sidebar.trainingOnline.scores,
    //         },
    //     ],
    // },
    //risk assessment
    {
        groupIcon: <ExclamationTriangle />,
        active: 'false',
        groupName: Translations.sidebar.riskAssessment.name,
        key: 'risk',
        items: [
            {
                roles: Roles.risk_assessment.crud,
                path: Routers.riskAssessment.register,
                title: Translations.sidebar.riskAssessment.register,
                isDisplayVerticalNav: true,
            },
            {
                roles: Roles.risk_assessment.view,
                path: Routers.riskAssessment.onlineDataBase,
                title: Translations.sidebar.riskAssessment.onlineDataBase,
                isDisplayVerticalNav: true,
            },
        ],
    },
    ///IIR -Report
    // {
    //     groupIcon: <File />,
    //     active: 'false',
    //     groupName: Translations.sidebar.incidentReport.name,
    //     key: 'incident',
    //     items: [
    //         {
    //             roles: Roles.iir_report.report_modify,
    //             path: Routers.incidentReport.create,
    //             title: Translations.sidebar.incidentReport.create,
    //         },
    //         {
    //             roles: Roles.iir_report.report_view,
    //             path: Routers.incidentReport.report,
    //             title: Translations.sidebar.incidentReport.report,
    //         },
    //         {
    //             roles: Roles.iir_report.report_modify_investigator,
    //             path: Routers.incidentReport.config,
    //             title: Translations.sidebar.incidentReport.config,
    //         },
    //     ],
    // },
    //work follow up
    {
        groupIcon: <File />,
        active: 'false',
        groupName: Translations.sidebar.message.name,
        key: 'message',
        items: [
            {
                roles: Roles.message.crud,
                path: Routers.message.create,
                title: Translations.sidebar.message.name,
                isDisplayVerticalNav: true,
            },
        ],
    },
    //documentation
    {
        groupIcon: <Folder />,
        active: 'false',
        groupName: Translations.sidebar.library.name,
        key: 'document',
        items: [
            {
                roles: Roles.document.view,
                path: Routers.library.root,
                title: Translations.sidebar.library.name,
                isDisplayVerticalNav: true,
            },
        ],
    },
    // Legal document
    {
        groupIcon: <Scale />,
        active: 'false',
        groupName: Translations.legalDocument.name,
        key: 'legalDocument',
        items: [
            {
                ignorePms: false,
                roles: Roles.legalDocument.view,
                path: Routers.legalDocument.detail,
                title: Translations.common.addNew,
                isDisplayVerticalNav: false,
            },
            {
                ignorePms: false,
                roles: Roles.legalDocument.admin,
                path: Routers.legalDocument.create,
                title: Translations.common.addNew,
                isDisplayVerticalNav: true,
            },
            {
                ignorePms: false,
                roles: Roles.legalDocument.view,
                path: Routers.legalDocument.root,
                title: Translations.legalDocument.name,
                isDisplayVerticalNav: true,
            },
            {
                ignorePms: false,
                roles: Roles.document.view,
                path: Routers.legalDocument.group,
                title: Translations.legalDocument.group,
                isDisplayVerticalNav: true,
            },
        ],
    },
    //configuration
    {
        groupIcon: <Setting />,
        active: 'false',
        groupName: Translations.sidebar.config.name,
        key: 'config',
        items: [
            {
                roles: Roles.customer.view,
                path: Routers.customer,
                title: Translations.pageName.customer,
                isDisplayVerticalNav: true,
            },
            {
                roles: Roles.golden_rules.view,
                path: Routers.config.goldenRules,
                title: Translations.sidebar.config.goldenRules,
                isDisplayVerticalNav: true,
            },
            {
                roles: Roles.editor,
                path: Routers.config.company,
                title: Translations.sidebar.config.site,
                isDisplayVerticalNav: true,
            },
            {
                roles: Roles.org_unit.crud,
                path: Routers.config.companyCreate,
                title: Translations.common.addNew,
                isDisplayVerticalNav: false,
            },
            {
                roles: Roles.org_unit.crud,
                path: Routers.config.companyEdit,
                title: Translations.common.edit,
                isDisplayVerticalNav: false,
            },
            {
                roles: Roles.area.view,
                path: Routers.config.area,
                title: Translations.sidebar.config.area,
                isDisplayVerticalNav: true,
            },
            {
                roles: Roles.contractor.view,
                path: Routers.config.contractor,
                title: Translations.sidebar.config.contractor,
                isDisplayVerticalNav: true,
            },
            {
                roles: Roles.risk_level.view,
                path: Routers.config.riskLevel,
                title: Translations.sidebar.config.riskLevel,
                isDisplayVerticalNav: true,
            },
            {
                roles: Roles.editor,
                path: Routers.config.department,
                title: Translations.sidebar.config.department,
                isDisplayVerticalNav: true,
            },
            {
                roles: Roles.account_management.view,
                path: Routers.config.user,
                title: Translations.sidebar.config.account,
                isDisplayVerticalNav: true,
            },
            {
                roles: Roles.account_management.crud,
                path: Routers.user.action,
                title: "modify",
                isDisplayVerticalNav: false,
            },
            {
                roles: Roles.account_management.view,
                path: Routers.config.role,
                title: Translations.role.role,
                isDisplayVerticalNav: true,
            },
            // {
            //     roles: Roles.account_management.view,
            //     path: Routers.config.dashboard,
            //     title: Translations.sidebar.config.account,
            // },
            // Hot line
        ],
    },
    {
        groupIcon: <Call />,
        active: 'false',
        groupName: Translations.sidebar.hotline.name,
        key: 'hotline',
        items: [
            {
                ignorePms: true,
                roles: [],
                path: Routers.hotline.root,
                title: Translations.sidebar.hotline.name,
                isDisplayVerticalNav: true,
            },
        ],
    },
];

export function findRouteByCurrentPath(): AccordionItemChildProps | undefined {
    let group = appMenus.find((n) => n.items.some((s) => s.path == window.location.pathname));
    if (group) {
        let item = group.items.find((f) => f.path == window.location.pathname);
        if (item) item.groupName = group.groupName;
        return item;
    }
    return undefined;
}
