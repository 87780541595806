import { useState } from 'react';
import { Form } from 'react-bootstrap';
import { AsyncTypeahead, Typeahead } from 'react-bootstrap-typeahead';
import { SelectFieldProps, SelectOption } from '../SelectField';
import { BaseFormControlProps } from '../baseFormProps';
import { useController } from 'react-hook-form';

export interface SelectSearchFieldProps extends SelectFieldProps {
    onSearch?: (value: string) => void;
    isSearch?: boolean;
}
const SelectSearchField = ({
    options,
    onSearch,
    name,
    control,
    md,
    label,
    isMutiple,
    displayProp,
    disable,
    onValueChange,
    onValuesChange,
}: SelectSearchFieldProps) => {
    const {
        field,
        fieldState: { error },
    } = useController({
        name,
        control,
    });
    const onChangeSelected = (e: any) => {
        let val = isMutiple ? e.map((x: any) => x.value) : e[0]?.value;
        field.onChange(val);
        if (isMutiple) onValuesChange && onValuesChange(val);
        else onValueChange && onValueChange(val);
    };
    return (
        <div className={'col-md-' + md}>
            <Form.Group>
                <Form.Label>{label}</Form.Label>
                {onSearch ? (
                    <AsyncTypeahead
                        // filterBy={true}
                        id={name}
                        isLoading={false}
                        labelKey={'label'}
                        minLength={0}
                        onSearch={(e) => {
                            if (onSearch) onSearch(e);
                        }}
                        onChange={onChangeSelected}
                        options={options ?? []}
                        placeholder="Search ..."
                        multiple={isMutiple}
                        disabled={disable}
                    />
                ) : (
                    <Typeahead
                        id={name}
                        labelKey={displayProp ?? 'label'}
                        onChange={onChangeSelected}
                        options={options ?? []}
                        placeholder="Choose  "
                        multiple={isMutiple}
                        disabled={disable}
                    />
                )}
                <Form.Control.Feedback type="invalid">{error?.message}</Form.Control.Feedback>
            </Form.Group>
        </div>
    );
};

export default SelectSearchField;
