import React, { Fragment, useEffect, useMemo, useRef, useState } from 'react';
import { ActionButtons2 } from 'components/shared/ActionButtons';
import Card from 'components/shared/Card';
import { useNavigate } from 'react-router-dom';

import { PaginationBase, PaginationBaseRequest, defaultPaginationRequest } from 'api/models/common';
import { AreaSelect, CompanySelect, ContractorSelect } from 'components/Common';
import { RadioField, SelectField } from 'components/FormControl';
import Translations from 'i18n/translation';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FilterButton } from 'components/Button/FilterButton';
import { Badge, Button } from 'react-bootstrap';
import { ModalFullScreen } from 'components/Modal/ModalFullScreen';
import useQueryParams from 'hooks/useQueryParams';
import { formatDateTime } from 'helper';
import { PaginationTable } from 'components/shared/PaginationTable';
import { lang } from 'i18n/lang';
import DateTimeField from 'components/FormControl/DateTimeField';
import { EnumHelper } from 'helper/enum.helper';
import secRegisterApi from 'api/v2/sec/secRegisterApi';
import { AddButton } from 'components/Button';
import { Link } from 'react-router-dom';
import Routers from 'router/const/routers';
import { SECRegisterFilterRequest, SECRegisterResponse } from 'api/v2/sec/model/register.model';
import SECCheckInOutPage from '../checkInOut/secCheckInOutPage';
import SECRegisterDetailPage from './detail';
import { SECCheckInOutType, SECRegisterType } from 'enum/sec';
import { QrCodeScan } from 'react-bootstrap-icons';
import ScanQRButtonComponent from './scanQRButton.component';

const defaultToDate = new Date();
const defaultFromDate = new Date();
defaultFromDate.setDate(defaultFromDate.getDate() - 7);
// eslint-disable-next-line react-hooks/exhaustive-deps
const UserRegisterPage = ({ registerType }: { registerType: SECRegisterType }) => {
    const cols = useMemo(
        () => [
            {
                field: 'name',
                title: lang.text(Translations.common.name),
            },
            {
                field: 'organizationName',
                title: lang.text(Translations.form.area.name),
            },
            {
                field: 'contractorName',
                title: lang.text(Translations.contractor),
            },
            {
                field: 'content',
                title: lang.text(Translations.sec.content),
            },
            {
                field: 'checkInTime',
                title: lang.text(Translations.sec.checkInTime),
            },
            {
                field: 'checkOutTime',
                title: lang.text(Translations.sec.checkOutTime),
            },
            {
                field: 'createdTime',
                title: lang.text(Translations.common.createdTime),
            },
            {
                field: 'createdUserName',
                title: lang.text(Translations.common.createdBy),
            },
        ],
        []
    );
    const [currentDetailId, setCurrentDetailId] = useState<string | undefined | null>(undefined);

    var navigate = useNavigate();
    const { t } = useTranslation();
    const [paginationData, setPaginationData] = useState<PaginationBase<SECRegisterResponse>>();
    const [companyId, setCompanyId] = useState<string | null>(null);
    const { control, handleSubmit, getValues, reset, formState } = useForm();
    const { getQueryParam } = useQueryParams();
    useEffect(() => {
        let id = getQueryParam('id');
        if (id != currentDetailId) setCurrentDetailId(id);
    }, []);

    const handleFetchData = async (pagination: PaginationBaseRequest) => {
        var form = getValues();
        // return;
        let filter = {
            ...pagination,
            ...form,
            to: form.to.toEndDay(),
            organizationId: form.areaId ?? companyId,
            contractorId: form.contractorId,
            registerType: registerType,
        } as SECRegisterFilterRequest;

        secRegisterApi
            .getPagination(filter)
            .then((n) => {
                let pagi: PaginationBase = {
                    ...n,
                    datas:
                        n.datas?.map((n) => {
                            return {
                                ...n,
                                createdTime: formatDateTime(n.createdTime),
                                action: <ActionButtons2 id={n.id} />,
                                checkInTime: formatDateTime(n.from),
                                checkOutTime: formatDateTime(n.to),
                            };
                        }) ?? [],
                };
                console.log(pagi);
                setPaginationData(pagi);
            })
            .catch((e) => {});
    };

    return (
        <Fragment>
            <div>
                <Card>
                    <Card.Header>
                        <form className="w-100">
                            <div className="row">
                                <CompanySelect md={4} name="companyId" control={control} required={false} disable={false} readOnly={false} onCompanyChange={setCompanyId} />
                                <AreaSelect md={4} name="areaId" control={control} required={false} companyId={companyId} readOnly={false} />
                                <ContractorSelect md={4} name="contractorId" control={control} required={false} readOnly={false} />

                                <DateTimeField label={lang.text(Translations.common.fromDate)} md={4} control={control} readOnly={false} name={'from'} defaultDate={defaultFromDate} />
                                <DateTimeField label={lang.text(Translations.common.toDate)} md={4} control={control} readOnly={false} name={'to'} defaultDate={defaultToDate} />
                            </div>
                            <div className="row">
                                <div className="col-md-12 d-flex justify-content-end">
                                    <ScanQRButtonComponent type={SECCheckInOutType.CHECK_IN} className="mx-2" />
                                    <ScanQRButtonComponent type={SECCheckInOutType.CHECK_OUT} />
                                    <FilterButton type="button" onClick={() => handleFetchData(defaultPaginationRequest)} className="mx-1 " />
                                    <AddButton isRender={true} type="button" className="mt-0 " onClick={() => navigate(Routers.sec.registerCreate + '?type=' + registerType)} />
                                </div>
                            </div>
                        </form>
                    </Card.Header>
                    <Card.Body>
                        <PaginationTable
                            onSearch={handleFetchData}
                            onPaginate={handleFetchData}
                            onRowClick={(item) => {
                                setCurrentDetailId(item.id);
                                //   window.open(`${Routers.sec.registerDetail.replace(':id', item.id)}`);
                            }}
                            columns={cols}
                            pagination={paginationData}
                            isStriped={true}
                            disableSearch={false}
                            wrapperClass="mt-5"
                        />
                        <ModalFullScreen
                            title={lang.text(Translations.common.reportDetail)}
                            bodyModal={<SECRegisterDetailPage registerId={currentDetailId} />}
                            showModal={currentDetailId?.hasValue() ?? false}
                            handleCloseModal={() => {
                                setCurrentDetailId(undefined);
                            }}
                        />
                    </Card.Body>
                </Card>
            </div>
        </Fragment>
    );
};

export default UserRegisterPage;
