import { Card, Col, ProgressBar, Row } from 'react-bootstrap';
import ProgressItem from '../component/progressItem';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { SelectField, SelectOption } from 'components/FormControl';
import Translations from 'i18n/translation';
import { lang } from 'i18n/lang';
import { SIReportType } from 'enum/safetyInspection';
import { Fragment, useMemo, useState } from 'react';
import { SIDashboardResponse } from 'api/v2/safe-inspection/model/siReport';
import { AreaSelect, CompanySelect } from 'components/Common';
import { FilterButton } from 'components/Button/FilterButton';
import SITemplateSelect from '../templates/siTemplateSelect';
import siReportApi from 'api/v2/safe-inspection/siReportApi';

const SIReportDashboard = () => {
    const schemaFilter = yup.object().shape({
        areaId: yup.string().notRequired(),
        reportType: yup.string().required(),
        templateId: yup.string().required(),
    });
    const {
        formState: { errors: errrorFilter },
        reset,
        control,
        handleSubmit,
        getValues,
    } = useForm({
        resolver: yupResolver(schemaFilter),
    });
    const [data, setData] = useState<SIDashboardResponse | null>(null);
    const [templateId, setTemplateId] = useState<string | null>(null);
    const [companyId, setCompanyId] = useState<string | null>(null);
    const [reportType, setReportType] = useState<SIReportType>(SIReportType.Daily);
    const reportTypes: SelectOption[] = useMemo(
        () => [
            { label: lang.text(Translations.common.dailyReport), value: SIReportType.Daily },
            { label: lang.text(Translations.common.scheduleReport), value: SIReportType.Scheduled },
        ],
        []
    );
    const onLoadDatas = () => {
        var form = getValues();
        templateId &&
            siReportApi
                .getDashboard({
                    companyId: companyId,
                    areaId: form.areaId,
                    reportType: reportType,
                    templateId: templateId,
                })
                .then(setData)
                .catch((err) => {});
    };
    return (
        <Fragment>
            <Card>
                <Card.Body>
                    <form onSubmit={handleSubmit(onLoadDatas)}>
                        <div className="row">
                            <SelectField
                                label={lang.text(Translations.common.reportType)}
                                control={control}
                                name="reportType"
                                options={reportTypes}
                                isMutiple={false}
                                md={3}
                                onValueChange={(value) => {
                                    setReportType(value);
                                }}
                            />

                            <CompanySelect md={3} name="companyId" control={control} required={true} disable={false} readOnly={false} onCompanyChange={setCompanyId} />
                            <AreaSelect md={3} name="areaId" control={control} required={false} companyId={companyId} readOnly={false} />

                            <SITemplateSelect companyId={companyId} control={control} name="templateId" reportType={reportType} md={3} onValueChange={setTemplateId} />
                        </div>
                        <div className=" d-flex justify-content-end">
                            <FilterButton type="submit" text={lang.text(Translations.form.safetyInspections.loadTemplate)} />
                        </div>
                    </form>
                </Card.Body>
            </Card>

            {data && (
                <Row>
                    {data.groups.map((group) => (
                        <Col md={6}>
                            <Card className="card-block">
                                <Card.Header>
                                    <h4 className="mb-0">{group.groupTemplateName}</h4>
                                </Card.Header>
                                <Card.Body>
                                    {group.items.map((item) => (
                                        <ProgressItem text={item.itemTemplateName} value={item.percentOk} successText={`${item.percentOk}%`} errorText={`${item.percentFailed}%`} />
                                    ))}
                                </Card.Body>
                            </Card>
                        </Col>
                    ))}
                </Row>
            )}
        </Fragment>
    );
};
export default SIReportDashboard;
