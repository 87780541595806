import { EquipmentReportGroupRequest, EquipmentReportItemRequest } from 'api/models';
import UserSelectField from 'components/Common/UserSelect';
import { TextAreaField, UploadImageField, RadioField } from 'components/FormControl';
import DateTimeField from 'components/FormControl/DateTimeField';
import Translations from 'i18n/translation';
import { t, use } from 'i18next';
import { Fragment, useEffect, useState } from 'react';
import { Card, Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';

const ItemContent = ({
    onItemChange,
    group,
    item,
    readonly,
}: // showPIC
{
    onItemChange: (group: EquipmentReportGroupRequest, item: EquipmentReportItemRequest) => void;
    group: EquipmentReportGroupRequest;
    item: EquipmentReportItemRequest;
    readonly: boolean;
    // showPIC:boolean
}) => {
    const { control } = useForm();
    const [isOk, setIsOk] = useState<number>(1);
    const onValueChanged = (item: EquipmentReportItemRequest) => {
        onItemChange(group, item);
    };
    useEffect(() => {
        setIsOk(item.isOk ? 1 : 0);
    }, [item.isOk]);
    return (
        <Card>
            <Card.Body>
                <Fragment>
                    <RadioField
                        inline={true}
                        defaultValue={item.isOk ? 1 : 0}
                        label={item.itemTemplateName}
                        control={control}
                        name={'isOk' + item.itemTemplateId}
                        readonly={readonly}
                        onValueChange={(value: any) => {
                            setIsOk(value as number);
                            onValueChanged({
                                ...item,
                                isOk: (value as number) == 1 ? true : false,
                            });
                        }}
                        options={[
                            {
                                label: t(Translations.common.ok),
                                value: 1,
                                defaultChecked: item.isOk ==true,
                                className: 'success',
                            },
                            {
                                label: t(Translations.common.notOk),
                                value: 0,
                                className: 'error',
                                defaultChecked: item.isOk == false,
                            },
                        ]}
                    />
                    {!isOk && (
                        <Row>
                            <DateTimeField
                                label={t(Translations.equipment.inspectedDate)}
                                md={12}
                                isShowTimePicker={false}
                                control={control}
                                name="deadline"
                                readOnly={readonly}
                                defaultDate={item.deadline}
                                onValueChange={(date) => {
                                    onValueChanged({
                                        ...item,
                                        deadline: date,
                                    });
                                }}
                            />
                            {/* <UserSelectField
                                            readOnly={false}
                                            label={t(Translations.equipment.reportPersonInCharge)}
                                            name="managerId"
                                            control={control}
                                            required={true}
                                            isMutiple={false}
                                            onValueChange={(value) => {
                                                onValueChanged({
                                                    ...item,
                                                    description: value,
                                                });
                                            }}
                                            md={3}
                                        /> */}
                            <TextAreaField
                                name={`description`}
                                control={control}
                                defaultValue={item.description}
                                label={t(Translations.form.safetyInspections.notOkDescription)}
                                onValueChange={(value) => {
                                    console.log('description change', value);
                                    onValueChanged({
                                        ...item,
                                        description: value,
                                    });
                                }}
                                readOnly={readonly}
                            />
                            <TextAreaField
                                name={`solution`}
                                control={control}
                                label={t(Translations.form.safetyInspections.notOkComment)}
                                defaultValue={item.solution}
                                onValueChange={(value) => {
                                    onValueChanged({
                                        ...item,
                                        solution: value,
                                    });
                                }}
                                readOnly={readonly}
                            />
                            <UploadImageField
                                src={item.imagePathOne}
                                name={`imagePathOne`}
                                control={control}
                                btnText={t(Translations.common.upload)}
                                onValueChange={(value) => {
                                    onValueChanged({
                                        ...item,
                                        imagePathOne: value,
                                    });
                                }}
                                readOnly={readonly}
                            />

                            <UploadImageField
                                src={item.imagePathTwo}
                                name={`imagePathTwo`}
                                control={control}
                                btnText={t(Translations.common.upload)}
                                onValueChange={(value) => {
                                    onValueChanged({
                                        ...item,
                                        imagePathTwo: value,
                                    });
                                }}
                                readOnly={readonly}
                            />
                        </Row>
                    )}
                </Fragment>
            </Card.Body>
        </Card>
    );
};
export default ItemContent;
