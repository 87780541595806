import { Fragment, useState } from 'react';

import { InputField, RadioField, SelectField, SelectOption, TextAreaField, UploadImageField } from 'components/FormControl';
import Card from 'components/shared/Card';
import Translations from 'i18n/translation';
import { useTranslation } from 'react-i18next';

import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { lang } from 'i18n/lang';
import { AreaSelect, CompanySelect, ContractorSelect } from 'components/Common';
import { AddButton, SaveButton } from 'components/Button';
import { Accordion, Table } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { ActionButtons2 } from 'components/shared/ActionButtons';
import { formatDate, formatDateTime } from 'helper';
import UserSelectField from 'components/Common/UserSelect';
import SKPIAddObjectiveComponent from './skpiAddObjective.component';
import { SKPIActionRequest, SKPIObjectiveRequest, SKPIRequest } from 'api/v2/skpi/model/skpiRequest.model';
import skpiReportApi from 'api/v2/skpi/skpiApi';
import DateTimeField from 'components/FormControl/DateTimeField';
import { PPRTaskType } from 'enum/ppr';
import { DepartmentSelect } from 'components/Common/DepartmentSelect';
import { SKPIObjectTypeSelect } from './skpiTaskTypeSelect';
import DataListView from 'components/shared/DataListView';
import SkpiActionComponent from './actionViewList.component.';

const defaultFromDate = new Date();
const SKPICreatePage = () => {
    const { t } = useTranslation();
    let a: SKPIObjectiveRequest;
    const [companyId, setCompanyId] = useState<string>('');

    const [objectives, setObjectives] = useState<SKPIObjectiveRequest[]>([]);
    const [updatingObjectives, setUpdatingObjectives] = useState<SKPIObjectiveRequest>();

    const schema = yup.object().shape({
        areaId: yup.string().notRequired().nullable(),
        companyId: yup.string().required(),
        contractorId: yup.string().required(),
        pICId: yup.string().required(),
        targetObjectId: yup.string().required(),
        targetObjectName: yup.string().required(),
        fromDate: yup.date().required(),
        toDate: yup.date().required(),
        taskType: yup
            .mixed<PPRTaskType>() // Cast the type to your enum
            .oneOf(Object.values(PPRTaskType)) // Ensure the value is one of the enum options
            .required(),
    });
    const {
        control,
        getValues,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm({ resolver: yupResolver(schema) });

    const onSubmidAddObjective = (obj: SKPIObjectiveRequest) => {
        console.log(obj, 'add objectives');
        setObjectives([...objectives, obj]);
    };

    // Save change SKPI report
    const SaveChange = () => {
        if (!objectives.hasValue()) {
            toast.error('Nhập ít nhất 1 chỉ tiêu');
            return;
        }
        // if (confirm('Bạn có chắc chắn muốn tạo báo cáo?') === false) return;
        var form = getValues();
        var payload: SKPIRequest = {
            ...form,
            companyIds: [form.companyId],
            items: objectives,
            type: form.taskType,
        };
        skpiReportApi
            .create(payload)
            .then((res) => {
                console.log(res);
            })
            .catch(() => {});
    };

    const onDeleteObjectives = (action: SKPIObjectiveRequest) => {
        setObjectives(objectives.filter((n) => n.uiId !== action.uiId));
        return Promise.resolve();
    };

    return (
        <Fragment>
            <form onSubmit={handleSubmit(SaveChange)}>
                <Card>
                    <Card.Header className="d-flex justify-content-between">
                        <h4 className="card-title  ">
                            <span>Tạo báo cáo</span>
                        </h4>
                        <SaveButton />
                    </Card.Header>
                    <Card.Body>
                        <div className="row">
                            <CompanySelect md={3} name="companyId" control={control} required={true} disable={false} readOnly={false} onCompanyChange={setCompanyId} />
                            <ContractorSelect md={3} name="contractorId" control={control} readOnly={false} />

                            <DateTimeField md={3} label={lang.text(Translations.common.fromDate)} control={control} readOnly={false} name={'fromDate'} defaultDate={defaultFromDate} />
                            <DateTimeField md={3} label={lang.text(Translations.common.toDate)} control={control} readOnly={false} name={'toDate'} defaultDate={defaultFromDate} />
                            <UserSelectField md={3} readOnly={false} label={lang.text(Translations.common.pic)} name="pICId" control={control} required={true} isMutiple={false} />
                            <SKPIObjectTypeSelect companyId={companyId} md={3} control={control} onTargetObjectNameChanged={(name: string) => {}} />
                        </div>
                    </Card.Body>
                </Card>
            </form>

            <div className="row">
                <div className="col-md-6">
                    <Card className="border border-gray">
                        <Card.Header className="d-flex justify-content-between">
                            <h5 className="card-title  ">
                                {lang.text(Translations.common.addNew)} {lang.text(Translations.wpr.skpi.objectives).toLocaleLowerCase()}
                            </h5>
                        </Card.Header>
                        <Card.Body>
                            <SKPIAddObjectiveComponent onSubmidAddObjective={onSubmidAddObjective} currentTaskUpdate={updatingObjectives} companyId={companyId} />
                        </Card.Body>
                    </Card>
                </div>
                <div className="col-md-6">
                    <Card>
                        <Card.Header className="d-flex justify-content-between">
                            <h5 className="card-title  ">{lang.text(Translations.wpr.skpi.objectives)}</h5>
                        </Card.Header>
                        <Card.Body>
                            <div className="row">
                                <Accordion defaultActiveKey="0">
                                    {objectives.map((item, index) => (
                                        <Accordion.Item eventKey={index.toString()} className="">
                                            <Accordion.Header className="d-flex justify-content-between">
                                                <div className="d-flex w-100 justify-content-between px-2">
                                                    <span>
                                                        # {index + 1} {item.name}
                                                    </span>
                                                    <ActionButtons2
                                                        id={item.id ?? ''}
                                                        onDelete={(id: string) => {
                                                            onDeleteObjectives(item);
                                                            return Promise.resolve();
                                                        }}
                                                        editAction={() => {
                                                            setObjectives(objectives.filter((n) => n.uiId !== item.uiId));
                                                            setUpdatingObjectives({ ...item });
                                                        }}
                                                    />
                                                </div>
                                            </Accordion.Header>
                                            <Accordion.Body>
                                                <DataListView
                                                    datas={[
                                                        { label: lang.text(Translations.common.fromDate), value: formatDate(item.fromDate) },
                                                        { label: lang.text(Translations.common.toDate), value: formatDate(item.toDate) },
                                                        { label: lang.text(Translations.common.pic), value: item.pICFullName },
                                                        { label: lang.text(Translations.wpr.ppr.object), value: item.targetObjectName },
                                                        { label: lang.text(Translations.wpr.ppr.type), value: item.taskType },
                                                    ]}
                                                />
                                                <SkpiActionComponent actions={item.actions} />
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    ))}
                                </Accordion>
                            </div>
                        </Card.Body>
                    </Card>
                </div>
            </div>
        </Fragment>
    );
};

export default SKPICreatePage;
