import Translations from 'i18n/translation';
import { MouseEventHandler } from 'react';
import { Button } from 'react-bootstrap';
import { CheckAll} from 'react-bootstrap-icons';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { BaseButtonProps } from './BaseButton.props';
import Color from 'enum/color';

export interface CloseButtonProps {
    onClick?: MouseEventHandler;
    disable?: boolean;
    text?: string;
}

export function CloseButton(props: BaseButtonProps) {
    const { t } = useTranslation();
    return (
        <Button
            {...props}
            onClick={props.onClick}
            variant={Color.primary}
            size="sm"
            className="d-flex rounded btn mx-1"
        >
            <span className={`btn-inner ${props.text ?? 'me-2'}`}>
                <CheckAll />
            </span>
            {props.text ?? <div>{t(Translations.button.closeReport)}</div>}
        </Button>
    );
}
