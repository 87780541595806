import { useEffect } from 'react';
import { Form, Row, Col, FormCheck } from 'react-bootstrap';
import { Control, useController } from 'react-hook-form';

export interface RadioOption {
    label?: string;
    value: number | string;
    defaultChecked?: boolean;
    className: string;
}

export interface RadioFieldProps {
    name: string;
    control: Control<any>;
    label?: string;
    options?: RadioOption[];
    formGroupClasses?: string;
    readonly?: boolean;
    disabled?: boolean;
    defaultValue?: any;
    required?: boolean;
    inline: boolean;
    onValueChange?: (value: string | number) => void;
}

export function RadioField({ inline, required, name, control, label, options, formGroupClasses, readonly = false, disabled = false, onValueChange, defaultValue }: RadioFieldProps) {
    const {
        field: { value, onChange, onBlur },
        fieldState: { invalid, error },
    } = useController({
        name,
        control,
    });

    const handleChangeOption = (e: any) => {
        if (onValueChange) {
            onValueChange(e);
        }
    };
    useEffect(() => {
        defaultValue && onChange(defaultValue);
    }, [defaultValue]);

    return (
        <>
            <Form.Group className={`mb-3 ${formGroupClasses}`}>
                {label && (
                    <Form.Label htmlFor={name} className="">
                        {label}
                    </Form.Label>
                )}
                <Row>
                    {options?.map((item, index) => {
                        return (
                            <Col key={index}>
                                <Form.Check
                                    required={required}
                                    inline={inline}
                                    className={item.className}
                                    id={`radio-${item.value}}`}
                                    value={defaultValue ?? value}
                                    type="radio"
                                    label={item.label}
                                    name={name}
                                    onChange={(n) => {
                                        if (readonly) return;
                                        onChange(item.value);
                                        handleChangeOption(item.value);
                                    }}
                                >
                                    {/* <FormCheck.Input
                                        required={required}
                                        readOnly={readonly}
                                        disabled={disabled}
                                        type="radio"
                                        name={name}
                                        value={item.value}
                                        onChange={(e) => {
                                            if (readonly) return;
                                            onChange(item.value);
                                            handleChangeOption(item.value);
                                        }}
                                        defaultChecked={item.defaultChecked}
                                    />
                                    <FormCheck.Label className={`${item.className}`} htmlFor={name}>
                                        {item.label}
                                    </FormCheck.Label> */}
                                </Form.Check>
                            </Col>
                        );
                    })}
                </Row>
                <div className="invalid-feedback">{error?.message}</div>
            </Form.Group>
        </>
    );
}
