// @ts-nocheck

//react-bootstrap
import { Badge, Button, Form, Image, Modal } from 'react-bootstrap';

//components
import Card from 'components/shared/Card';
import { useEffect, useState } from 'react';
import usePromptInputText from 'components/Modal/PromptInputText';
import sorReportApi from 'api/v2/sor/sorReportApi';
import { SORReportDetailResponse } from 'api/v2/sor/model/sorReport.model';
import DataListView, { DataListViewItem } from 'components/shared/DataListView';
import Translations from 'i18n/translation';
import Color from 'enum/color';
import { lang } from 'i18n/lang';
import { formatDateTime } from 'helper';
import ImageDisplay from 'components/Images/ImageDisplay/imageDisplay';
import { CloseButton } from 'components/Button/CloseButton';
import { ModalDetailProps } from 'models/modalDetailProps';
import { DeleteButton } from 'components/Button';
import ToastConfirm from 'components/Modal/ToastConfirm';
const SORDetailPage = ({ id, onDeleted }: ModalDetailProps) => {
    const [report, setReport] = useState<SORReportDetailResponse>(null);
    const [datas, setDatas] = useState<DataListViewItem[]>([]);
    const { openPromptInputText, PromptInputText } = usePromptInputText();

    useEffect(() => {
        loadReport();
    }, []);

    const onCloseReport = () => {
        report &&
            sorReportApi
                .closeSReport(report.id)
                .then((n) => {
                    loadReport();
                })
                .catch((n) => {});
    };

    const loadReport = () => {
        if (id)
            sorReportApi
                .getById(id)
                .then((r: SORReportDetailResponse) => {
                    setReport(r);
                    try {
                        let dts = [
                            {
                                label: lang.text(Translations.common.reportType),
                                value: (
                                    <div className="p-0">
                                        <Badge bg={r.isSafe ? Color.success : Color.danger}>{r.isSafe ? lang.text(Translations.common.safe) : lang.text(Translations.common.unSafe)} </Badge>
                                    </div>
                                ),
                            },
                            {
                                label: lang.text(Translations.common.status),
                                value: (
                                    <div className="p-0">
                                        <Badge bg={r.status ? Color.success : Color.danger}>{r.status}</Badge>
                                    </div>
                                ),
                            },
                            {
                                label: lang.text(Translations.common.createdBy),
                                value: r.createdUserName,
                            },
                            {
                                label: lang.text(Translations.common.createdTime),
                                value: r.createdTime && formatDateTime(r.createdTime),
                            },
                            {
                                label: lang.text(Translations.form.site.name),
                                value: r.companyName,
                            },
                            {
                                label: lang.text(Translations.form.area.name),
                                value: r.areaName,
                            },

                            {
                                label: lang.text(Translations.contractor),
                                value: r.contractorName,
                            },

                            {
                                label: lang.text(Translations.sor.category),
                                value: r.categoryName,
                            },

                            {
                                label: lang.text(Translations.sidebar.config.goldenRules),
                                value: r.goldenRuleName,
                            },

                            {
                                label: lang.text(Translations.sidebar.config.riskLevel),
                                value: r.riskLevelName,
                            },
                            {
                                label: lang.text(Translations.sor.target),
                                value: r.target,
                            },
                            {
                                label: lang.text(Translations.sor.target),
                                value: r.solution,
                            },
                        ];
                        setDatas(dts);
                    } catch (error) {}
                })
                .catch((n) => {});
    };
    const onDelete = () => {
        if (id) {
            sorReportApi
                .delete(id)
                .then(() => props.onDeleted && props.onDeleted())
                .catch(() => {});
        }
    };
    return (
        <div>
            <Card>
                {(report?.canClose || report?.canDelete) && (
                    <div className="row">
                        <div className="d-flex justify-content-end">
                            {report?.canClose && <CloseButton onClick={onCloseReport} />}
                            {report?.canDelete && (
                                <ToastConfirm message={lang.text(Translations.modal.confirm.confirmDelete)} onConfirm={onDelete}>
                                    <DeleteButton />
                                </ToastConfirm>
                            )}
                        </div>
                    </div>
                )}

                {datas && <DataListView datas={datas} />}
                <div className="row">
                    {report?.imagePathOne && (
                        <div className="col-md-6">
                            <ImageDisplay src={report?.imagePathOne} />
                        </div>
                    )}
                    {report?.imagePathTwo && (
                        <div className="col-md-6">
                            <ImageDisplay src={report?.imagePathTwo} />
                        </div>
                    )}
                </div>
            </Card>
        </div>
    );
};

export default SORDetailPage;
