import React, { useState, ChangeEvent, useEffect } from 'react';
import { Control, useController, useForm } from 'react-hook-form';
import English from 'flatpickr/dist/l10n'; // Correct import path for English locale
import { Form } from 'react-bootstrap';
import { BaseFormControlProps } from 'components/FormControl/baseFormProps';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import './dateTimeFiels.module.css';

interface DateTimeFieldProps extends BaseFormControlProps {
    isShowTimePicker?: boolean; // Optional time picker
    onValueChange?: (date: Date) => void;
    defaultDate?: Date;
}
const timeZoneLocal = new Date().getTimezoneOffset() / -60;
const DateTimeField: React.FC<DateTimeFieldProps> = (props: DateTimeFieldProps) => {
    const {
        field: { value, onChange, onBlur },
        fieldState: { invalid, error },
    } = useController({
        name: props.name,
        control: props.control,
        rules: props.required ? { required: 'This field is required' } : {},
    });

    const [currentDate, setCurrentDate] = useState<Date>(new Date());
    useEffect(() => {
        if (props.defaultDate) {
            onChange(new Date(props.defaultDate));
        }
    }, []);

    useEffect(() => {
        setCurrentDate(value);
    }, [value]);

    const onChangeDate = (date: Date) => {
        if (!date) onChange(date);
        let timeZoneDate = date.getTimezoneOffset() / -60;
        if (timeZoneLocal != timeZoneDate) {
            date = moment(date).add(timeZoneLocal, 'h').toDate();
        }

        if (!props.isShowTimePicker) {
            date.setHours(0);
            date.setMinutes(0);
            date.setSeconds(0);
            date.setMilliseconds(0);
        }
        onChange(date);
    };
    return (
        <div className={'col-md-' + props.md}>
            <Form.Group className={`mb-3 ${props.formGroupClasses} w-100`}>
                {props.label && (
                    <Form.Label htmlFor={props.name} style={{ marginRight: 3 }}>
                        {props.label} {props.required && <span className="text-danger">*</span>}
                    </Form.Label>
                )}
                <br />
                <DatePicker
                    className={`form-control w-100 ${error?.message ? 'is-invalid' : ''}`}
                    showTimeSelect={props.isShowTimePicker}
                    selected={currentDate}
                    onChange={(date: Date) => {
                        onChangeDate(date);
                    }}
                    timeFormat="p"
                    timeIntervals={30}
                    readOnly={props.readOnly}
                    // showTimeInput={props.isShowTimePicker}
                    dateFormat={props.isShowTimePicker ? 'dd-MM-yyyy HH:mm' : 'dd-MM-yyyy'}
                    name={props.name}
                />
                <div className="invalid-feedback d-blocksecond d-block ">{error?.message}</div>
            </Form.Group>
        </div>
    );
};

export default DateTimeField;
