import React, { Fragment, useEffect, useMemo, useRef, useState } from 'react';
import Card from 'components/shared/Card';
import { useNavigate } from 'react-router-dom';

import { PaginationBase, PaginationBaseRequest, defaultPaginationRequest } from 'api/models/common';
import Translations from 'i18n/translation';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { PaginationTable } from 'components/shared/PaginationTable';
import { lang } from 'i18n/lang';
import { AddButton } from 'components/Button';
import { ActionButtons2 } from 'components/shared/ActionButtons';
import useModalHook from 'components/ModalHook';
import roleApi from 'api/v2/role/roleApi';
import { RoleCreateRequest, RoleResponse, RoleUpdateRequest } from 'api/v2/role/model/role.model';
import RoleCreateComponent from './roleCreate.component';
import { useAuth } from 'providers/contexts';

const defaultFromDate = new Date();
defaultFromDate.setDate(defaultFromDate.getDate() - 7);

// eslint-disable-next-line react-hooks/exhaustive-deps
const RoleListPage = () => {
    const { tokenProfile } = useAuth();
    const [paginationData, setPaginationData] = useState<PaginationBase<any>>();
    const { openModal, onClose, onCompleted, Prompt } = useModalHook();
    console.log(tokenProfile?.pms);
    useEffect(() => {
        handleFetchData(defaultPaginationRequest);
    }, []);
    const cols = useMemo(
        () => [
            {
                field: 'name',
                title: lang.text(Translations.common.name),
            },
            {
                field: 'action',
                title: '',
            },
        ],
        []
    );
    const handleFetchData = async (pagination: PaginationBaseRequest) => {
        roleApi
            .getPagination(pagination)
            .then((n) => {
                let pagi: PaginationBase = {
                    ...n,
                    datas:
                        n.datas?.map((n) => {
                            return {
                                ...n,
                                action: <ActionButtons2 id={n.id} editPms={tokenProfile?.pms.roleAdmin} onDelete={onDelete} editAction={() => onUpdate(n)} />,
                            };
                        }) ?? [],
                };
                setPaginationData(pagi);
            })
            .catch((e) => {});
    };
    const onAdd = async () => {
        var request: RoleCreateRequest = await openModal({
            title: lang.text(Translations.equipment.templateCategories),
            body: <RoleCreateComponent name="" onClose={onClose} onCompleted={onCompleted} permissionIds={[]} />, //<SORCategoryForm description="" name="" id="" onClose={onClose} onCompleted={onCompleted} />,
        });
        roleApi
            .create(request)
            .then(() => {
                handleFetchData(defaultPaginationRequest);
            })
            .catch(() => {});
    };

    const onUpdate = async (data: RoleResponse) => {
        var request: RoleUpdateRequest = await openModal({
            title: lang.text(Translations.equipment.templateCategories),
            body: <RoleCreateComponent name={data.name} onClose={onClose} onCompleted={onCompleted} permissionIds={data.permissionIds ?? []} />, //<SORCategoryForm description="" name="" id="" onClose={onClose} onCompleted={onCompleted} />,
        });
        if (request) {
            request.id = data.id;
            roleApi
                .update(request)
                .then(() => {
                    handleFetchData(defaultPaginationRequest);
                })
                .catch(() => {});
        }
    };
    const onDelete = async (id: string) => {
        roleApi
            .delete(id)
            .then(() => {
                handleFetchData(defaultPaginationRequest);
            })
            .catch(() => {});
    };
    return (
        <Fragment>
            <Card>
                <Card.Body>
                    <div className="d-flex justify-content-end">
                        <AddButton isRender={true} onClick={onAdd} />
                    </div>
                    <PaginationTable
                        onSearch={handleFetchData}
                        onPaginate={handleFetchData}
                        onRowClick={(item) => {}}
                        columns={cols}
                        pagination={paginationData}
                        isStriped={true}
                        disableSearch={true}
                        wrapperClass="mt-5"
                    />
                </Card.Body>
            </Card>
            {Prompt}
        </Fragment>
    );
};

export default RoleListPage;
