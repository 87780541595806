import { formatDateTime } from 'helper';
import React, { memo, Fragment } from 'react';

//react-bootstrap
import { Col, Row, Card } from 'react-bootstrap';

//router
import { Link } from 'react-router-dom';

export interface TimelineItem {
    title: string;
    description?: any;
    date: Date;
    createdFulName?: string;
}
interface TimelineProps {
    items: TimelineItem[];
    title?: string;
}
const TimelineComponent = ({ items, title }: TimelineProps) => {
    return (
        <Fragment>
            <Row>
                <Col lg="12">
                    <Card>
                        {title && (
                            <Card.Header>
                                <Card.Title as="h5">{title}</Card.Title>
                            </Card.Header>
                        )}

                        <Card.Body>
                            <div className="iq-timeline0 m-0 d-flex align-items-center justify-content-between position-relative">
                                <ul className="list-inline p-0 m-0">
                                    {items &&
                                        items.map((item, index: number) => (
                                            <li key={index}>
                                                <div className={`timeline-dots timeline-dot1 ${index % 2 == 0 ? 'border-warning' : 'border-info'} text-primary`}></div>
                                                <h6 className="float-left mb-1">{item.title} </h6>
                                                <small className="float-right mt-1">
                                                    {formatDateTime(item.date)} {item.createdFulName && ' - '}
                                                    {item.createdFulName}
                                                </small>
                                                <div className="d-inline-block w-100">
                                                    {item.description}
                                                </div>
                                            </li>
                                        ))}
                                </ul>
                            </div>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Fragment>
    );
};

export default TimelineComponent;
