import { SIReportGroupResponse, SIReportItemRequest } from 'api/v2/safe-inspection/model/siReport';
import { SITemplateGroupModel } from 'api/v2/safe-inspection/model/siTemplate';
import { TextAreaField, UploadImageField, RadioField } from 'components/FormControl';
import DateTimeField from 'components/FormControl/DateTimeField';
import DataListView, { DataListViewItem } from 'components/shared/DataListView';
import { group } from 'console';
import Color from 'enum/color';
import { formatDateTime } from 'helper';
import { lang } from 'i18n/lang';
import Translations from 'i18n/translation';
import { data } from 'jquery';
import { Fragment, useEffect, useState } from 'react';
import { Badge, Button, Card, Row } from 'react-bootstrap';
import { Control, useForm } from 'react-hook-form';

const SIItemContent = ({
    item,
    readonly,
    control,
    showPIC,
    groupTemplateId,
    generageName,
}: // showPIC
{
    item: SIReportItemRequest;
    readonly: boolean;
    showPIC: boolean;
    groupTemplateId: string;
    control: Control<any>;
    generageName: (groupId: string, itemId: string, name: string) => string;
}) => {
    const [isOk, setIsOk] = useState<number>(1);

    useEffect(() => {
        setIsOk(item.isOk ? 1 : 0);
    }, [item.isOk]);

    const generageNameLocal = (name: string) => {
        return generageName(groupTemplateId, item.itemTemplateId, name);
    };
    const renderReadOnly = () => {
        let datas: DataListViewItem[] = [
            {
                label: <b className="p-0">{item.itemTemplateName}</b>,
                value: <Badge bg={isOk ? Color.softSuccess : Color.softDanger}>{isOk ? lang.text(Translations.common.ok) : lang.text(Translations.common.notOk)}</Badge>,
            },
        ];
        if (!isOk) {
            datas.push({
                label: lang.text(Translations.form.safetyInspections.deadline),
                value: item.deadline && formatDateTime(item.deadline),
            });
            datas.push({
                label: lang.text(Translations.form.safetyInspections.notOkDescription),
                value: item.description,
            });
        }
        return (
            <Fragment>
                <DataListView datas={datas} />
                <Row>
                    <UploadImageField src={item.imagePathOne} name={generageNameLocal('imagePathOne')} control={control} readOnly={readonly} />
                    <UploadImageField src={item.imagePathTwo} name={generageNameLocal('imagePathTwo')} control={control} readOnly={readonly} />
                </Row>
            </Fragment>
        );
    };

    const renderForEdit = () => (
        <Fragment>
            <RadioField
                inline={true}
                defaultValue={item.isOk ? 1 : 0}
                label={item.itemTemplateName}
                control={control}
                name={generageNameLocal('isOk')}
                readonly={readonly}
                onValueChange={(value: any) => {
                    setIsOk(value as number);
                }}
                options={[
                    {
                        label: lang.text(Translations.common.ok),
                        value: 1,
                        defaultChecked: item.isOk,
                        className: 'success',
                    },
                    {
                        label: lang.text(Translations.common.notOk),
                        value: 0,
                        className: 'error',
                        defaultChecked: item.isOk == false,
                    },
                ]}
            />

            {!isOk && (
                <Row>
                    <DateTimeField
                        label={lang.text(Translations.form.safetyInspections.deadline)}
                        md={12}
                        isShowTimePicker={false}
                        control={control}
                        readOnly={readonly}
                        name={generageNameLocal('deadline')}
                        defaultDate={item.deadline ?? new Date()}
                        onValueChange={(date) => {}}
                    />

                    <TextAreaField
                        name={generageNameLocal('description')}
                        control={control}
                        defaultValue={item.description}
                        label={lang.text(Translations.form.safetyInspections.notOkDescription)}
                        readOnly={readonly}
                    />

                    <UploadImageField
                        src={item.imagePathOne}
                        name={generageNameLocal('imagePathOne')}
                        control={control}
                        btnText={lang.text(Translations.common.upload)}
                        onValueChange={(value) => {
                            // onValueChanged({
                            //     ...item,
                            //     imagePathOne: value,
                            // });
                        }}
                        readOnly={readonly}
                    />

                    <UploadImageField
                        src={item.imagePathTwo}
                        name={generageNameLocal('imagePathTwo')}
                        control={control}
                        btnText={lang.text(Translations.common.upload)}
                        onValueChange={(value) => {
                            // onValueChanged({
                            //     ...item,
                            //     imagePathTwo: value,
                            // });
                        }}
                        readOnly={readonly}
                    />
                </Row>
            )}
        </Fragment>
    );

    return (
        <Card>
            <Card.Body>
                <Fragment>{readonly ? renderReadOnly() : renderForEdit()}</Fragment>
            </Card.Body>
        </Card>
    );
};
export default SIItemContent;
