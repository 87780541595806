// @ts-nocheck

//react-bootstrap
import { Accordion, Badge, Button, Form, Image, Modal } from 'react-bootstrap';

//components
import Card from 'components/shared/Card';
import { useEffect, useState } from 'react';
import usePromptInputText from 'components/Modal/PromptInputText';
import DataListView from 'components/shared/DataListView';
import Translations from 'i18n/translation';
import { lang } from 'i18n/lang';
import { formatDate, formatDateTime } from 'helper';
import { CloseButton } from 'components/Button/CloseButton';
import { ModalDetailProps } from 'models/modalDetailProps';
import { DeleteButton } from 'components/Button';
import ToastConfirm from 'components/Modal/ToastConfirm';
import { SkpiStatus } from 'enum/ppr';
import useModalHook from 'components/ModalHook';
import { SKPIDetailResponse } from 'api/v2/skpi/model/skpiResponse.model';
import skpiReportApi from 'api/v2/skpi/skpiApi';
import SkpiActionComponent from './actionViewList.component.';
import PPRCloseComponent from '../ppr/close.component';
import { PPRReportCloseRequest } from 'api/v2/ppr/model/ppr.model';
import { SKPICloseObjectivesRequest } from 'api/v2/skpi/model/skpiRequest.model';
import { EnumHelper } from 'helper/enum.helper';
import { toast } from 'react-toastify';
const SKPIDetailPage = ({ id, onDeleted }: ModalDetailProps) => {
    const [report, setReport] = useState<SKPIDetailResponse>(null);
    const { openPromptInputText, PromptInputText } = usePromptInputText();
    const { openModal, onClose, onCompleted, Prompt } = useModalHook();

    useEffect(() => {
        loadReport();
    }, []);
    const onCloseObjective = async (id: string) => {
        var rsL: PPRReportCloseRequest = await openModal({
            title: lang.text(Translations.wpr.skpi.closeObjectives),
            body: <PPRCloseComponent companyId={report.companyId} id={report.id} onClose={onClose} onCompleted={onCompleted} />,
        });
        var payload: SKPICloseObjectivesRequest = {
            sKPIId: report.id,
            pPRLevelId: rsL.pPRLevelId,
            objectivesIds: [id],
            closedDescription: rsL.description,
        };

        rsL &&
            skpiReportApi
                .closeItem(payload)
                .then((n) => {
                    loadReport();
                })
                .catch((n) => {});
    };
    const onCloseReport = async () => {
        var rs: PPRReportCloseRequest = await openModal({
            title: lang.text(Translations.common.closeReport),
            body: <PPRCloseComponent companyId={report.companyId} id={report.id} onClose={onClose} onCompleted={onCompleted} />,
        });
        rs &&
            skpiReportApi
                .closeSkpi({
                    sKPIId: report.id,
                    pPRLevelId: rs.pprLevelId,
                    closedDescription: rs.description,
                })
                .then((n) => {
                    loadReport();
                })
                .catch((n) => {});
    };
    // sKPIId: string;
    // pPRLevelId: string;
    // closedDescription: string;
    const loadReport = () => {
        if (id)
            skpiReportApi
                .getById(id)
                .then((r: SKPIDetailResponse) => {
                    setReport(r);
                })
                .catch((n) => {});
    };
    const onDelete = () => {
        if (id) {
            skpiReportApi
                .delete(id)
                .then(() => props.onDeleted && props.onDeleted())
                .catch(() => {});
        }
    };
    return (
        <div>
            {report && (
                <Card className="">
                    <Card.Body>
                        <div className="row my-2">
                            <div className="d-flex justify-content-end">
                                {report?.canClose && <CloseButton onClick={onCloseReport} />}
                                {report?.canDelete && (
                                    <ToastConfirm message={lang.text(Translations.modal.confirm.confirmDelete)} onConfirm={onDelete}>
                                        <DeleteButton />
                                    </ToastConfirm>
                                )}
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6">
                                <DataListView
                                    headerClosedInfo
                                    datas={[
                                        {
                                            label: lang.text(Translations.form.site.name),
                                            value: report.companyName,
                                        },
                                        {
                                            label: lang.text(Translations.contractor),
                                            value: report.contractorName,
                                        },
                                        {
                                            label: lang.text(Translations.common.pic),
                                            value: report.pICFullName,
                                        },
                                        {
                                            label: lang.text(Translations.common.createdBy),
                                            value: report.createdUserName,
                                        },
                                        {
                                            label: lang.text(Translations.common.createdTime),
                                            value: report.createdTime && formatDateTime(report.createdTime),
                                        },
                                    ]}
                                />
                            </div>
                            <div className="col-md-6">
                                {report.status == SkpiStatus.CLOSED && report.closedInfo && (
                                    <DataListView
                                        headerClosedInfo
                                        datas={[
                                            {
                                                label: lang.text(Translations.common.closedBy),
                                                value: report.closedInfo.fullName,
                                            },
                                            {
                                                label: lang.text(Translations.common.closedDate),
                                                value: formatDateTime(report.closedInfo.date),
                                            },
                                            {
                                                label: lang.text(Translations.common.description),
                                                value: report.closedInfo.description,
                                            },
                                            {
                                                label: lang.text(Translations.wpr.ppr.level),
                                                value: report.pPRLevelName,
                                            },
                                        ]}
                                    />
                                )}
                            </div>
                        </div>
                    </Card.Body>
                </Card>
            )}
            {report && (
                <Card>
                    <div className="row">
                        <div className="row">
                            <Accordion defaultActiveKey="0">
                                {report.items &&
                                    report.items.map((item, index) => (
                                        <Accordion.Item eventKey={index.toString()} className="" key={index}>
                                            <Accordion.Header className="d-flex justify-content-between">
                                                <div className="d-flex w-100 justify-content-between px-2">
                                                    <span>
                                                        # {index + 1} {item.targetObjectName}
                                                    </span>

                                                    <Badge color={EnumHelper.getSKPIStatusColor(item.status)}>{item.status}</Badge>
                                                </div>
                                            </Accordion.Header>
                                            <Accordion.Body>
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <DataListView
                                                            bordered
                                                            headerGeneralInfo
                                                            datas={[
                                                                { label: lang.text(Translations.common.toDate), value: formatDate(item.toDate) },
                                                                { label: lang.text(Translations.common.fromDate), value: formatDate(item.fromDate) },
                                                                { label: lang.text(Translations.wpr.ppr.type), value: item.taskType },
                                                                { label: lang.text(Translations.wpr.ppr.object), value: item.targetObjectName },
                                                                { label: lang.text(Translations.common.pic), value: item.pICFullName },
                                                            ]}
                                                        />
                                                    </div>
                                                    <div className="col-md-6">
                                                        {item.canClose && <CloseButton text={lang.text(Translations.wpr.skpi.closeObjectives)} onClick={() => onCloseObjective(item.id)} />}
                                                        {item.status != SkpiStatus.OPENING && item.closedInfo && (
                                                            <DataListView
                                                                headerClosedInfo
                                                                datas={[
                                                                    {
                                                                        label: lang.text(Translations.common.closedBy),
                                                                        value: item.closedInfo.fullName,
                                                                    },
                                                                    {
                                                                        label: lang.text(Translations.common.closedDate),
                                                                        value: formatDateTime(item.closedInfo.date),
                                                                    },
                                                                    {
                                                                        label: lang.text(Translations.common.description),
                                                                        value: item.closedInfo.description,
                                                                    },
                                                                    {
                                                                        label: lang.text(Translations.wpr.ppr.level),
                                                                        value: item.pPRLevelName,
                                                                    },
                                                                ]}
                                                            />
                                                        )}
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <h6 className="text-primary mt-2">{lang.text(Translations.wpr.skpi.actions)} </h6>
                                                        <SkpiActionComponent actions={item.actions ?? []} />
                                                    </div>
                                                </div>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    ))}
                            </Accordion>
                        </div>
                    </div>
                </Card>
            )}
            {Prompt}
        </div>
    );
};

export default SKPIDetailPage;
