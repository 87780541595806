import React, { Fragment, useEffect, useMemo, useRef, useState } from 'react';
import { ActionButtons2 } from 'components/shared/ActionButtons';
import Card from 'components/shared/Card';
import { useNavigate } from 'react-router-dom';

import { PaginationBase, PaginationBaseRequest, defaultPaginationRequest } from 'api/models/common';
import { AreaSelect, CompanySelect } from 'components/Common';
import Translations from 'i18n/translation';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FilterButton } from 'components/Button/FilterButton';
import { ModalFullScreen } from 'components/Modal/ModalFullScreen';
import useQueryParams from 'hooks/useQueryParams';
import { formatDateTime } from 'helper';
import { PaginationTable } from 'components/shared/PaginationTable';
import UserSelectField from 'components/Common/UserSelect';
import { lang } from 'i18n/lang';
import RiskLevelSelect from 'components/RiskLevelSelect';
import DateTimeField from 'components/FormControl/DateTimeField';
import { RAFilterRequest } from 'api/v2/risk-assessment/model/raRequest.model';
import { RAResponse } from 'api/v2/risk-assessment/model/rs.Response.model';
import raApi from 'api/v2/risk-assessment/raApi';
import RATagSelect from './tagSelect';
import RADetailPage from './raDetailtPage';
import { set } from 'lodash';

const defaultToDate = new Date();
const defaultFromDate = new Date();
defaultFromDate.setDate(defaultFromDate.getDate() - 7);
// eslint-disable-next-line react-hooks/exhaustive-deps
const RAListPage = () => {
    const cols = useMemo(
        () => [
            {
                field: 'raName',
                title: lang.text(Translations.form.site.name),
            },
            {
                field: 'companyName',
                title: lang.text(Translations.form.area.name),
            },
            {
                field: 'areaName',
                title: lang.text(Translations.form.area.name),
            },
            {
                field: 'createdTime',
                title: lang.text(Translations.common.createdTime),
            },
            {
                field: 'createdUserName',
                title: lang.text(Translations.common.createdBy),
            },
        ],
        []
    );
    // const reportStatus = useMemo(() => SORReportHelper.getReportStatusOptions(), []);
    const [currentDetailId, setCurrentDetailId] = useState<string | undefined | null>(undefined);

    var navigate = useNavigate();
    const { t } = useTranslation();
    const [paginationData, setPaginationData] = useState<PaginationBase<RAResponse>>();
    const [companyId, setCompanyId] = useState<string | null>(null);
    const { control, handleSubmit, getValues, reset, formState } = useForm();
    const { getQueryParam } = useQueryParams();
    useEffect(() => {
        let id = getQueryParam('id');
        setCurrentDetailId(id);
    }, []);

    const handleFetchData = async (pagination: PaginationBaseRequest) => {
        var form = getValues();
        // return;
        let filter = {
            ...pagination,
            ...form,
        } as RAFilterRequest;
        filter.toDate = filter.toDate?.toEndDay();
        raApi
            .getPagination(filter)
            .then((n) => {
                let pagi: PaginationBase = {
                    ...n,
                    datas:
                        n.datas?.map((n) => {
                            return {
                                ...n,
                                createdTime: formatDateTime(n.createdTime),
                                // status: <Badge bg={SORReportHelper.getStatusColor(n.status)}>{n.status}</Badge>,
                                // reportType: <Badge bg={SORReportHelper.getReportTypeColor(n.isSafe)}>{SORReportHelper.getReportTypeText(n.isSafe)}</Badge>,
                                action: <ActionButtons2 id={n.id} />,
                            };
                        }) ?? [],
                };
                setPaginationData(pagi);
            })
            .catch((e) => {});
    };

    return (
        <Fragment>
            <div>
                <Card>
                    <Card.Header>
                        <form className="w-100" onSubmit={handleSubmit(() => handleFetchData(defaultPaginationRequest))}>
                            <div className="row">
                                <CompanySelect md={3} name="companyId" control={control} required={false} disable={false} readOnly={false} onCompanyChange={setCompanyId} />
                                <AreaSelect md={3} name="areaId" control={control} required={false} companyId={companyId} readOnly={false} />
                                <RiskLevelSelect name="riskLevelId" md={3} control={control} required={false} readOnly={false} />
                                <UserSelectField readOnly={false} label={t(Translations.common.pic)} name="pICId" control={control} required={false} isMutiple={false} md={3} />
                                <DateTimeField label={lang.text(Translations.common.fromDate)} md={3} control={control} readOnly={false} name={'fromDate'} defaultDate={defaultFromDate} />
                                <DateTimeField label={lang.text(Translations.common.toDate)} md={3} control={control} readOnly={false} name={'toDate'} defaultDate={defaultToDate} />{' '}
                                <RATagSelect md={3} isMulti={true} control={control} name="tagIds" />
                                <div className="col-md-3">
                                    <FilterButton type="submit" className="mx-2 mt-3" />
                                </div>
                            </div>
                        </form>
                    </Card.Header>
                    <Card.Body>
                        <PaginationTable
                            // onSearch={handleFetchData}
                            onPaginate={handleFetchData}
                            onRowClick={(item) => {
                                setCurrentDetailId(item.id);
                            }}
                            columns={cols}
                            pagination={paginationData}
                            isStriped={true}
                            disableSearch={true}
                            wrapperClass="mt-5"
                        />
                        <ModalFullScreen
                            title={lang.text(Translations.common.reportDetail)}
                            bodyModal={
                                <RADetailPage
                                    id={currentDetailId}
                                    onDeleted={() => {
                                        setCurrentDetailId(undefined);
                                        handleFetchData(defaultPaginationRequest);
                                    }}
                                />
                            }
                            showModal={currentDetailId?.hasValue() ?? false}
                            handleCloseModal={() => {
                                setCurrentDetailId(undefined);
                            }}
                        />
                    </Card.Body>
                </Card>
            </div>
        </Fragment>
    );
};

export default RAListPage;
