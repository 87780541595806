import { PromptModal } from 'components/Modal/PromptModal/index';
//components
import { yupResolver } from '@hookform/resolvers/yup';
import contractorApi from 'api/v2/configContractorApi';
import { ContractorCreateRequest, ContractorResponse, ContractorUpdateRequest } from 'api/models/organization';

import { CustomToggle, FancyTable } from 'components/shared';
import Card from 'components/shared/Card';
import Translations from 'i18n/translation';
import { Suspense, lazy, memo, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { PaginationBase } from 'api/models/common';
import { AddButton } from 'components/Button';
import { avatarCompanyDefault } from 'constants/common';
import { ActionButtons2 } from 'components/shared/ActionButtons';
import { PaginationTable } from 'components/shared/PaginationTable';
const ContractorPage = memo(() => {
    const { t } = useTranslation();

    const [isShowModelUpdate, setIsShowModelUpdate] = useState<boolean>(false);
    const [isShowModelAdd, setIsShowModelAdd] = useState<boolean>(false);
    const [seletedData, setSelectedData] = useState<ContractorResponse | null>(null);
    const [data, setData] = useState<PaginationBase<ContractorResponse>>();
    const [openModal, setOpenModal] = useState<boolean>(false);

    useEffect(() => {
        handleFetchData();
    }, []);

    const handleFetchData = () => {
        contractorApi
            .getByCurrentCustomer()
            .then((result: PaginationBase<ContractorResponse>) => {
                setData(result);
            })
            .catch((err) => {});
    };

    const onAcceptDelete = async (id: string) => {
        await contractorApi.delete(id ?? '');
        handleFetchData();
    };

    const onCancelUpdate = () => {
        setSelectedData(null);
        setIsShowModelUpdate(false);
    };

    const onSubmitUpdate = (newName: string, id: string | null) => {
        var request: ContractorUpdateRequest = {
            id: id ?? '',
            contractorName: newName,
        };
        contractorApi
            .update(request)
            .then(() => {
                handleFetchData();
            })
            .catch((err) => {});

        setIsShowModelUpdate(false);
    };

    const onSubmiAdd = (newName: string, id: string | null) => {
        var request: ContractorCreateRequest = {
            contractorName: newName,
        };
        contractorApi
            .create(request)
            .then(() => {
                handleFetchData();
            })
            .catch((err) => {});

        setIsShowModelAdd(false);
    };

    const onShowModalUpdate = (area: ContractorResponse) => {
        setSelectedData(area);
        setIsShowModelUpdate(true);
    };

    const schema = yup.object().shape({
        parentId: yup.string(),
    });

    const { control, handleSubmit, getValues } = useForm<{ parentId?: string | undefined }>({
        defaultValues: {
            parentId: '',
        },
        resolver: yupResolver(schema),
    });
    const cols = useMemo(
        () => [
            {
                field: 'logo',
                title: '',
            },
            {
                field: 'name',
                title: t(Translations.form.name),
            },
            {
                field: 'action',
                title: '',
            },
        ],
        []
    );

    return (
        <div>
            {seletedData && (
                <PromptModal
                    title={t(Translations.common.edit)}
                    id={seletedData.id}
                    value={seletedData.contractorName}
                    onSubmitForm={onSubmitUpdate}
                    onCanceled={onCancelUpdate}
                    isUseCustomShow={true}
                    isCustomShow={isShowModelUpdate}
                ></PromptModal>
            )}
            <Card>
                <Card.Header>
                    <div className="text-end w-100 d-flex justify-content-end align-items-center">
                        <PromptModal
                            title={t(Translations.common.addNew)}
                            value=""
                            onSubmitForm={onSubmiAdd}
                            onCanceled={() => {
                                setIsShowModelAdd(false);
                            }}
                        >
                            <AddButton
                                isRender={true}
                                onClick={() => {
                                    setIsShowModelAdd(true);
                                }}
                            />
                        </PromptModal>
                    </div>
                </Card.Header>
                <Card.Body>
                    {/* Table */}
                    {/* {data && <FancyTable hideHead={true} loading={status === 'loading' ? true : false} columns={cols} data={tableData} isStriped={true} wrapperClass="mt-5" />} */}
                    <PaginationTable
                        onSearch={handleFetchData}
                        onPaginate={handleFetchData}
                        // onRowClick={(item) => {
                        //     setCurrentDetailId(item.id);
                        // }}
                        columns={cols}
                        pagination={data}
                        isStriped={true}
                        wrapperClass="mt-5"
                    />
                </Card.Body>
            </Card>
        </div>
    );
});

export default ContractorPage;
