import { useState } from 'react';

import { RadioField, TextAreaField, UploadImageField } from 'components/FormControl';
import Card from 'components/shared/Card';
import Translations from 'i18n/translation';
import { useTranslation } from 'react-i18next';

import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { lang } from 'i18n/lang';
import { AreaSelect, CompanySelect, ContractorSelect } from 'components/Common';
import SORCategorySelect from '../component/sorCategorySelect.component';
import RiskLevelSelect from 'components/RiskLevelSelect';
import GoldenRuleSelect from '../component/goldenRuleSelect.component';
import { SaveButton } from 'components/Button';
import UserSelectField from 'components/Common/UserSelect';
import DateTimeField from 'components/FormControl/DateTimeField';
import { SORReportCreateRequest } from 'api/v2/sor/model/sorReport.model';
import sorReportApi from 'api/v2/sor/sorReportApi';

const defaultDate = new Date();
const SORCreatePage = () => {
    const { t } = useTranslation();

    const [companyId, setCompanyId] = useState<string>('');
    const schema = yup.object().shape({
        isSafe: yup.boolean().required('isSafe is required'),
        deadline: yup.date().required('Deadline is required'),
        companyId: yup.string().required('Company ID is required'),
        areaId: yup.string().required('Area ID is required'),
        sORCategoryId: yup.string().required('SoR Category ID is required'),
        pICId: yup.string().required('PIC ID is required'),
        goldenRuleId: yup.string().required('Golden Rule ID is required'),
        contractorId: yup.string().required('Contractor ID is required'),
        riskLevelId: yup.string().required('Risk Level ID is required'),
        solution: yup.string().required('Solution  is required'),
    });

    const {
        control,
        watch,
        handleSubmit,
        getValues,
        reset,
        formState: { errors },
    } = useForm<SORReportCreateRequest>({
        resolver: yupResolver(schema),
    });

    const SaveChange = () => {
        var form = getValues();
        var payload: SORReportCreateRequest = {
            ...form,
            isSafe: (form.isSafe as any) == 'true' ? true : false,
        };
        sorReportApi
            .create(payload)
            .then((res) => {
                console.log(res);
            })
            .catch(() => {});
    };

    return (
        <form onSubmit={handleSubmit(SaveChange)} onClick={() => console.log(errors)}>
            <Card>
                <Card.Header className="d-flex justify-content-between">
                    <h4 className="card-title  ">
                        <span>Tạo báo cáo</span>{' '}
                    </h4>{' '}
                    <SaveButton />
                </Card.Header>
                <Card.Body>
                    <div className="row">
                        <div className="col-md-6">
                            <RadioField
                                inline={true}
                                control={control}
                                name={'isSafe'}
                                readonly={false}
                                defaultValue={'true'}
                                options={[
                                    {
                                        label: lang.text(Translations.common.safe),
                                        value: 'true',
                                        defaultChecked: true,
                                        className: 'success',
                                    },
                                    {
                                        label: lang.text(Translations.common.unSafe),
                                        value: 'false',
                                        className: 'error',
                                        defaultChecked: false,
                                    },
                                ]}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <CompanySelect md={4} name="companyId" control={control} required={true} disable={false} readOnly={false} onCompanyChange={setCompanyId} />
                        <AreaSelect md={4} name="areaId" control={control} required={true} companyId={companyId} readOnly={false} />
                        <ContractorSelect md={4} name="contractorId" control={control} required={true} readOnly={false} />
                        <SORCategorySelect name="sORCategoryId" md={4} control={control} required={true} readOnly={false} />
                        <RiskLevelSelect name="riskLevelId" md={4} control={control} required={true} readOnly={false} />
                        <GoldenRuleSelect name="goldenRuleId" md={4} control={control} required={true} readOnly={false} />
                        <TextAreaField name={'target'} control={control} defaultValue={''} label={lang.text(Translations.sor.target)} readOnly={false} />
                        <TextAreaField name={'solution'} control={control} defaultValue={''} label={lang.text(Translations.sor.solution)} readOnly={false} />
                        <UserSelectField readOnly={false} label={t(Translations.common.pic)} name="pICId" control={control} required={true} isMutiple={false} md={6} />

                        <DateTimeField
                            label={lang.text(Translations.form.safetyInspections.deadline)}
                            md={6}
                            isShowTimePicker={false}
                            control={control}
                            readOnly={false}
                            name={'deadline'}
                            defaultDate={defaultDate}
                            onValueChange={(date) => {}}
                        />
                    </div>

                    <div className="row">
                        <UploadImageField name={'imagePathOne'} control={control} readOnly={false} />
                        <UploadImageField name={'imagePathTwo'} control={control} readOnly={false} />
                    </div>
                </Card.Body>
            </Card>
        </form>
    );
};

export default SORCreatePage;
