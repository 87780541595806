import client from './client';
import { ChangePasswordRequest, LoginRequest, LoginResponse } from './user/model';
import { RoleResponse } from './role/model/role.model';

const baseUserApi = '/auth';
const authApi = {
    login: async (data: LoginRequest) => {
        const url = `${baseUserApi}/login`;
        var rs = await client.post<LoginResponse>(url, data, true);
        return rs;
    },
    changepass: async (data: ChangePasswordRequest) => {
        const url = `${baseUserApi}/changepass`;
        var rs = await client.postNonWrap<unknown>({
            endpoint: url,
            data: data,
            isLoading: true,
            isShowSuccess: true,
            isShowError: true,
        });
        return rs;
    },
};

export default authApi;
