import UserRegisterPage from './userRegister';
import { Badge, Tab, Tabs } from 'react-bootstrap';
import { SECRegisterType } from 'enum/sec';

const defaultToDate = new Date();
const defaultFromDate = new Date();
defaultFromDate.setDate(defaultFromDate.getDate() - 7);
// eslint-disable-next-line react-hooks/exhaustive-deps
const SECRegisterPage = () => {
    return (
        <Tabs defaultActiveKey="home" id="uncontrolled-tab-example" className="mb-3">
            <Tab eventKey="home" title={'Người dùng'}>
                <UserRegisterPage registerType={SECRegisterType.PERSON} />
            </Tab>
            <Tab eventKey="profile" title="Thiết bị">
                <UserRegisterPage registerType={SECRegisterType.EQUIPMENT} />
            </Tab>
        </Tabs>
    );
};

export default SECRegisterPage;
