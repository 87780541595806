import { RouteObject } from 'react-router-dom';

import Routers from 'router/const/routers';
import { ReportPage, TripPage } from 'views/trip';

const tripRouters: RouteObject[] = [
  {
    path: Routers.trip.trip,
    element: <TripPage />,
  },
  {
    path: Routers.trip.report,
    element: <ReportPage />,
  },
];

export default tripRouters;
