import { LoginRequest, LoginResponse } from '../../api/v2/user/model/login.model';
import { Fragment, memo, useEffect } from 'react';

//react-bootstrap
import { Button, Col, Image, Row } from 'react-bootstrap';

//router
import { Navigate, useLocation, useNavigate } from 'react-router-dom';

//components
import Card from 'components/shared/Card';

// img
import { Logo } from 'assets/images';
import { AuthO1 } from 'assets/images/auth';

// Import selectors & action from setting store

// Redux Selector / Action
import { selectIsLoggin, selectTokenProfile, setIsLoggin, setTokenProfile } from 'app/appSlice';
import LoginForm from 'views/auth/loginForm';
// import useAsync from 'hooks/useAsync';
import { SelectLanguage } from 'components/shared';
import { t } from 'i18next';
import Translations from 'i18n/translation';
import authApi from 'api/v2/authApi';
import useQueryParams from 'hooks/useQueryParams';
import { useAppSelector } from 'app/hooks';
import { useAuth } from 'providers/contexts';

const SignInPage = () => {
    const { loginSuccess, handleRefesh } = useAuth();
    const isLoggin = useAppSelector(selectIsLoggin);
    const initialValues: LoginRequest = {
        phoneNumber: '',
        password: '',
    };
    const navigate = useNavigate();
    const { getQueryParam } = useQueryParams();

    const handleLoginFormSubmit = async (formValues: LoginRequest) => {
        var rs = await authApi.login(formValues);

        if (rs.isSuccess && rs.result.access_token) {
            loginSuccess(rs.result.access_token);
            navigate(getQueryParam('url') ?? '/', { replace: true });
        }
    };
    useEffect(() => {
        handleRefesh();
    }, []);
    
    return (
        <Fragment>
            <section className="login-content">
                <Row className="m-0 align-items-center bg-white vh-100">
                    <Col md="6">
                        <Row className="justify-content-center">
                            <Col md="10">
                                <Card className="card-transparent shadow-none d-flex justify-content-center mb-0 auth-card">
                                    <Card.Body>
                                        <div className="d-flex justify-content-end align-items-center mb-5">
                                            <SelectLanguage />
                                        </div>

                                        <span className="navbar-brand d-flex align-items-center mb-3">
                                            <Image src={Logo} width={30} height={30} alt="logo" />

                                            <h4 className="logo-title ms-3">HSEVN</h4>
                                        </span>
                                        <h2 className="mb-2 text-center">{t(Translations.pages.signin.name)}</h2>
                                        <p className="text-center">{t(Translations.pages.signin.subTitle)}</p>

                                        <LoginForm initialValues={initialValues} onSubmit={handleLoginFormSubmit} />
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </Col>
                    <Col md="6" className="d-md-block d-none bg-primary p-0 mt-n1 vh-100 overflow-hidden">
                        <Image src={AuthO1} className="Image-fluid gradient-main animated-scaleX" alt="images" />
                    </Col>
                </Row>
            </section>
        </Fragment>
    );
};

SignInPage.displayName = 'SignInPage';
export default SignInPage;
