import { useState } from 'react';
import { ConfirmModal } from 'components/shared';

interface Props {
    children: React.ReactNode;
    onConfirm: () => void;
    onCanceled?: () => void;
    message?: string;
}
const ToastConfirm = ({ children, onCanceled, onConfirm, message }: Props) => {
    const [openConfirm, setOpenConfirm] = useState<boolean>(false);
    return (
        <>
            <ConfirmModal
                show={openConfirm}
                content={message}
                handleCancel={() => {
                    setOpenConfirm(false);
                    onCanceled && onCanceled();
                }}
                handleAccept={() => {
                    onConfirm && onConfirm();
                    setOpenConfirm(false);
                }}
            />
            <div onClick={() => setOpenConfirm(true)}>{children}</div>
        </>
    );
};
export default ToastConfirm;

// <ConfirmToast
//     asModal={asModal}
//     customCancel={customCancel}
//     customConfirm={customConfirm}
//     customFunction={customFunction}
//     message={message}
//     position={position}
//     showCloseIcon={showCloseIcon}
//     theme={'light'}
// >
//     {children}
// </ConfirmToast>
