import client from './client';
import { EquipmentReportType } from 'enum';
import { PaginationBase, PaginationBaseRequest } from '../models/common';
import {
    EquipmentTypeGroupCreateRequest,
    EquipmentTypeGroupUpdateRequest,
    EquipmentTypeGroupResponse,
    EquipmentReportTemplateResponse,
    EquipmentReportTemplateCreateRequest,
    EquipmentReportTemplateUpdateRequest,
    EquipmentReportTemplateGroupResponse,
    EquipmentReportTemplateGroupCreateRequest,
    EquipmentReportTemplateGroupUpdateRequest,
    EquipmentReportTemplateItemCreateRequest,
    EquipmentReportTemplateItemUpdateRequest,
    EquipmentCreateRequest,
    EquipmentUpdateRequest,
    EquipmentFilterRequest,
    EquipmentResponse,
    EquipmentDetailResponse,
} from 'api/models/equipments';
import { SelectOption } from 'components/FormControl';

const baseEquipmentTypeUrl = '/equipmentTypeGroup';
const baseEquipmentTemplateUrl = '/equipmentReportTemplate';
const baseEquipmentReportUrl = '/equipmentReport';
const baseEquipmentUrl = '/equipment';
const equipmentApi = {
    // ========== EQUIPMENT START ==============//

    getById(id: string): Promise<EquipmentDetailResponse> {
        const url = `${baseEquipmentUrl}/getbyid/${id}`;
        return client.getNonWrap<EquipmentDetailResponse>({
            endpoint: url,
        });
    },
    forDropdown(filter: EquipmentFilterRequest, isLoading: boolean = true): Promise<SelectOption[]> {
        const url = `${baseEquipmentUrl}/forDropdown`;
        return client.getNonWrap<SelectOption[]>({
            endpoint: url,
            data: filter,
            isLoading: isLoading,
        });
    },
    getPagination(filter: EquipmentFilterRequest, isLoading: boolean = true): Promise<PaginationBase<EquipmentResponse>> {
        const url = `${baseEquipmentUrl}/GetByIndex`;
        return client.getNonWrap<PaginationBase<EquipmentResponse>>({
            endpoint: url,
            data: filter,
            isLoading: isLoading,
        });
    },
    create(data: EquipmentCreateRequest): Promise<string> {
        const url = `${baseEquipmentUrl}/create`;
        return client.postNonWrap<string>({
            endpoint: url,
            data: data,
        });
    },

    update(data: EquipmentUpdateRequest): Promise<unknown> {
        const url = `${baseEquipmentUrl}/update`;
        return client.postNonWrap<unknown>({
            endpoint: url,
            data: data,
        });
    },
    delete(euipmentId: string): Promise<unknown> {
        const url = `${baseEquipmentUrl}/delete/${euipmentId}`;
        return client.postNonWrap<unknown>({
            endpoint: url,
        });
    },
    // ==========  EQUIPMENT END  ==============//

    // ========== ITEM ==============//
    getTemplateForCreateReport(equipmentId: string, reportType: EquipmentReportType): Promise<EquipmentReportTemplateGroupResponse[]> {
        const url = `${baseEquipmentTemplateUrl}/forCreate/reporttype/${reportType}/equipment/${equipmentId}`;
        return client.getNonWrap<EquipmentReportTemplateGroupResponse[]>({
            endpoint: url,
        });
    },
    createTemplateItem(data: EquipmentReportTemplateItemCreateRequest): Promise<string> {
        const url = `${baseEquipmentTemplateUrl}/item-create`;
        return client.postNonWrap<string>({
            endpoint: url,
            data: data,
        });
    },
    updateTemplateItem(data: EquipmentReportTemplateItemUpdateRequest): Promise<unknown> {
        const url = `${baseEquipmentTemplateUrl}/item-update`;
        return client.postNonWrap<string>({
            endpoint: url,
            data: data,
        });
    },
    deleteTemplateItem(id: string): Promise<unknown> {
        const url = `${baseEquipmentTemplateUrl}/item-delete/${id}`;
        return client.postNonWrap<string>({
            endpoint: url,
        });
    },
    // ========== ITEM END==============//

    // ========== GROUP==============//
    createTemplateGroup(data: EquipmentReportTemplateGroupCreateRequest): Promise<string> {
        const url = `${baseEquipmentTemplateUrl}/group-create`;
        return client.postNonWrap<string>({
            endpoint: url,
            data: data,
        });
    },
    updateTemplateGroup(data: EquipmentReportTemplateGroupUpdateRequest): Promise<unknown> {
        const url = `${baseEquipmentTemplateUrl}/group-update`;
        return client.postNonWrap<string>({
            endpoint: url,
            data: data,
        });
    },
    deleteTemplateGroup(id: string): Promise<unknown> {
        const url = `${baseEquipmentTemplateUrl}/group-delete/${id}`;
        return client.postNonWrap<string>({
            endpoint: url,
        });
    },
    // ========== GROUP END==============//

    // template
    createTemplate(data: EquipmentReportTemplateCreateRequest): Promise<string> {
        const url = `${baseEquipmentTemplateUrl}/create`;
        return client.postNonWrap<string>({
            endpoint: url,
            data: data,
        });
    },
    updateTemplate(data: EquipmentReportTemplateUpdateRequest): Promise<unknown> {
        const url = `${baseEquipmentTemplateUrl}/update`;
        return client.postNonWrap<string>({
            endpoint: url,
            data: data,
        });
    },
    deleteTemplate(id: string): Promise<unknown> {
        const url = `${baseEquipmentTemplateUrl}/delete/${id}`;
        return client.postNonWrap<string>({
            endpoint: url,
        });
    },
    getTemplateById(id: string): Promise<EquipmentReportTemplateResponse> {
        const url = `${baseEquipmentTemplateUrl}/getbyid/${id}`;
        return client.getNonWrap<EquipmentReportTemplateResponse>({
            endpoint: url,
        });
    },
    getTemplatePagination(filter: PaginationBaseRequest): Promise<PaginationBase<EquipmentReportTemplateResponse>> {
        const url = `${baseEquipmentTemplateUrl}/GetByIndex`;
        return client.getNonWrap<PaginationBase<EquipmentReportTemplateResponse>>({
            endpoint: url,
            data: filter,
        });
    },

    getTemplateDropdown(reportType: EquipmentReportType | null, typeGroupId: string): Promise<EquipmentReportTemplateResponse[]> {
        var filter = {
            currentPage: 1,
            rowsPerPage: 1000,
            reportType: reportType,
        };
        const url = `${baseEquipmentTemplateUrl}/report-Dropdown/type/${typeGroupId}`;
        return client.getNonWrap<EquipmentReportTemplateResponse[]>({
            endpoint: url,
            data: filter,
        });
    },
    // type group
    createType(data: EquipmentTypeGroupCreateRequest): Promise<string> {
        const url = `${baseEquipmentTypeUrl}/create`;
        return client.postNonWrap<string>({
            endpoint: url,
            data: data,
        });
    },
    updateType(data: EquipmentTypeGroupUpdateRequest): Promise<unknown> {
        const url = `${baseEquipmentTypeUrl}/update`;
        return client.postNonWrap<string>({
            endpoint: url,
            data: data,
        });
    },
    deleteType(id: string): Promise<unknown> {
        const url = `${baseEquipmentTypeUrl}/delete/${id}`;
        return client.postNonWrap<string>({
            endpoint: url,
        });
    },
    getTypeById(id: string): Promise<EquipmentTypeGroupResponse> {
        const url = `${baseEquipmentTypeUrl}/getbyid/${id}`;
        return client.getNonWrap<EquipmentTypeGroupResponse>({
            endpoint: url,
        });
    },
    getTypePagination(filter: PaginationBaseRequest): Promise<PaginationBase<EquipmentTypeGroupResponse>> {
        const url = `${baseEquipmentTypeUrl}/GetByIndex`;
        return client.getNonWrap<PaginationBase<EquipmentTypeGroupResponse>>({
            endpoint: url,
            data: filter,
        });
    },
    getTypeByCompanies(companies: string[]): Promise<EquipmentTypeGroupResponse[]> {
        if (!companies.hasValue()) return Promise.resolve([]);
        const url = `${baseEquipmentTypeUrl}/byCompanies?` + companies.toQueryParams('companies');
        return client.getNonWrap<EquipmentTypeGroupResponse[]>({
            endpoint: url,
            isLoading: false,
        });
    },
};

export default equipmentApi;
