import client from '../client';
import { PaginationBase } from 'api/models/common';
import { RADetailResponse, RAResponse } from './model/rs.Response.model';
import { RACreateRequest, RAFilterRequest } from './model/raRequest.model';
import { SORDashboardResponse } from '../sor/model/sorReport.model';

const baseURL = '/RiskAssessment';
const raApi = {
    getById(id: string): Promise<RADetailResponse> {
        const url = `${baseURL}/getbyid/${id}`;
        return client.getNonWrap<RADetailResponse>({
            endpoint: url,
        });
    },
    getPagination(filter: RAFilterRequest, isLoading: boolean = true): Promise<PaginationBase<RAResponse>> {
        const url = `${baseURL}/GetByIndex`;
        return client.getNonWrap<PaginationBase<RAResponse>>({
            endpoint: url,
            data: filter,
            isLoading: isLoading,
        });
    },
    create(data: RACreateRequest): Promise<string> {
        const url = `${baseURL}/create`;
        return client.postNonWrap<string>({
            endpoint: url,
            data: data,
            isLoading: true,
            isShowError: true,
        });
    },

    update(data: RACreateRequest): Promise<unknown> {
        const url = `${baseURL}/update`;
        return client.postNonWrap<unknown>({
            endpoint: url,
            data: data,
        });
    },
    delete(id: string): Promise<unknown> {
        const url = `${baseURL}/delete/${id}`;
        return client.postNonWrap<unknown>({
            endpoint: url,
        });
    },

    // closeSReport(reportId: string): Promise<unknown> {
    //     const url = `${baseURL}/close/${reportId}`;
    //     return client.postNonWrap<unknown>({
    //         endpoint: url,
    //     });
    // },
    getDashboard(filter: any): Promise<SORDashboardResponse> {
        const url = `${baseURL}/dashboard`;
        return client.getNonWrap<any>({
            endpoint: url,
            data: filter,
        });
    },
};

export default raApi;
