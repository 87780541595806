import { ResponseApi } from 'api/models/common/apiResponse';
import client from './client';
import StorageKeys from 'constants/storageKeys';
import {
    EquipmentReportByTypeGroupResponse,
    EquipmentReportCreateRequest,
    EquipmentReportDetailResponse,
    EquipmentReportFilterRequest,
    EquipmentReportResponse,
    EquipmentReportUpdateRequest,
} from '../models';
import { PaginationBase } from '../models/common';
import { List } from 'choices.js/public/types/src/scripts/components';

const baseURL = '/EquipmentReport';
const equipmentReportApi = {
    getById(id: string): Promise<EquipmentReportDetailResponse> {
        const url = `${baseURL}/getbyid/${id}`;
        return client.getNonWrap<EquipmentReportDetailResponse>({
            endpoint: url,
        });
    },
    getPagination(
        filter: EquipmentReportFilterRequest,
        isLoading: boolean = true
    ): Promise<PaginationBase<EquipmentReportResponse>> {
        const url = `${baseURL}/GetByIndex`;
        return client.getNonWrap<PaginationBase<EquipmentReportResponse>>({
            endpoint: url,
            data: filter,
            isLoading: isLoading,
        });
    },
    create(data: EquipmentReportCreateRequest): Promise<string> {
        const url = `${baseURL}/create`;
        return client.postNonWrap<string>({
            endpoint: url,
            data: data,
        });
    },

    update(data: EquipmentReportUpdateRequest): Promise<unknown> {
        const url = `${baseURL}/update`;
        return client.postNonWrap<unknown>({
            endpoint: url,
            data: data,
        });
    },
    delete(euipmentId: string): Promise<unknown> {
        const url = `${baseURL}/delete/${euipmentId}`;
        return client.postNonWrap<unknown>({
            endpoint: url,
        });
    },
    close(id: string, description: string): Promise<unknown> {
        const url = `${baseURL}/close`;
        return client.postNonWrap<unknown>({
            endpoint: url,
            data: { id: id, description: description },
        });
    }
};

export default equipmentReportApi;
