import { RouteObject } from 'react-router-dom';

import Routers from 'router/const/routers';
import {
    EquipmentTemplateDetail,
    EquipmentCreateReportPage,
    EquipmentListPage,
    EquipmentReportDetailPage,
    EquipmentTemplatePage,
    EquipmentTypePage,
} from 'views/equipment';
import EquipmentCreatePage from 'views/equipment/equipment/create';
import EquipmentModifyPage from 'views/equipment/equipment/modify';
import EquipmentDetailPage from 'views/equipment/equipment/detail';
import EquipmentReportListPage from 'views/equipment/reports/listPage';

const equipmentRouters: RouteObject[] = [
    {
        path: Routers.equipment.create,
        element: <EquipmentCreatePage />,
    },
    {
        path: Routers.equipment.modify,
        element: <EquipmentModifyPage />,
    },
    {
        path: Routers.equipment.detail,
        element: <EquipmentDetailPage />,
    },
    {
        path: Routers.equipment.checkListDetail,
        element: <EquipmentTemplateDetail />,
    },
    {
        path: Routers.equipment.reportCreate,
        element: <EquipmentCreateReportPage />,
    },
   
    {
        path: Routers.equipment.checkList,
        element: <EquipmentTemplatePage />,
    },
    {
        path: Routers.equipment.list,
        element: <EquipmentListPage />,
    },
    {
        path: Routers.equipment.report,
        element: <EquipmentReportListPage />,
    },
    {
        path: Routers.equipment.reportDetail,
        element: <EquipmentReportDetailPage />,
    },
    {
        path: Routers.equipment.type,
        element: <EquipmentTypePage />,
    },
];

export default equipmentRouters;
