import React, { Fragment, useEffect, useMemo, useRef, useState } from 'react';
import { ActionButtons2 } from 'components/shared/ActionButtons';
import Card from 'components/shared/Card';
import { useNavigate } from 'react-router-dom';

import { PaginationBase, PaginationBaseRequest, defaultPaginationRequest } from 'api/models/common';
import { AreaSelect, CompanySelect, ContractorSelect } from 'components/Common';
import { RadioField, SelectField } from 'components/FormControl';
import Translations from 'i18n/translation';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FilterButton } from 'components/Button/FilterButton';
import { Badge } from 'react-bootstrap';
import { ModalFullScreen } from 'components/Modal/ModalFullScreen';
import useQueryParams from 'hooks/useQueryParams';
import { formatDateTime } from 'helper';
import { PaginationTable } from 'components/shared/PaginationTable';
import UserSelectField from 'components/Common/UserSelect';
import { lang } from 'i18n/lang';
import DateTimeField from 'components/FormControl/DateTimeField';
import { EnumHelper } from 'helper/enum.helper';
import SKPIDetailPage from './skpiDetailPage';
import { SKPIResponse } from 'api/v2/skpi/model/skpiResponse.model';
import { SKPIFilterRequest } from 'api/v2/skpi/model/skpiRequest.model';
import skpiReportApi from 'api/v2/skpi/skpiApi';
import { SKPIObjectTypeSelect } from './skpiTaskTypeSelect';

const defaultToDate = new Date();
const defaultFromDate = new Date();
defaultFromDate.setDate(defaultFromDate.getDate() - 7);
// eslint-disable-next-line react-hooks/exhaustive-deps
const SKPIListPage = () => {
    const cols = useMemo(
        () => [
            {
                field: 'companyName',
                title: lang.text(Translations.form.site.name),
            },
            {
                field: 'contractorName',
                title: lang.text(Translations.contractor),
            },
            {
                field: 'status',
                title: lang.text(Translations.common.status),
            },
            {
                field: 'type',
                title: lang.text(Translations.wpr.ppr.type),
            },
            {
                field: 'targetObjectName',
                title: lang.text(Translations.wpr.ppr.object),
            },
            {
                field: 'createdTime',
                title: lang.text(Translations.common.createdTime),
            },
            {
                field: 'createdUserName',
                title: lang.text(Translations.common.createdBy),
            },
            // {
            //     field: 'action',
            //     title: '',
            // },
        ],
        []
    );
    const reportStatus = useMemo(() => EnumHelper.generatePPRReportStatus(), []);
    const [currentDetailId, setCurrentDetailId] = useState<string | undefined | null>(undefined);

    var navigate = useNavigate();
    const { t } = useTranslation();
    const [paginationData, setPaginationData] = useState<PaginationBase<SKPIResponse>>();
    const [companyId, setCompanyId] = useState<string>('');
    const { control, handleSubmit, getValues, reset, formState } = useForm();
    const { getQueryParam } = useQueryParams();
    useEffect(() => {
        let id = getQueryParam('id');
        setCurrentDetailId(id);
    }, []);

    const handleFetchData = async (pagination: PaginationBaseRequest) => {
        var form = getValues();
        // return;
        let filter = {
            ...pagination,
            ...form,
            type: form.taskType,
        } as SKPIFilterRequest;
        filter.toDate = filter.toDate?.toEndDay();
        skpiReportApi
            .getPagination(filter)
            .then((n) => {
                let pagi: PaginationBase = {
                    ...n,
                    datas:
                        n.datas?.map((n) => {
                            return {
                                ...n,
                                createdTime: formatDateTime(n.createdTime),
                                status: <Badge bg={EnumHelper.getSKPIStatusColor(n.status)}>{n.status}</Badge>,
                                action: <ActionButtons2 id={n.id} />,
                            };
                        }) ?? [],
                };
                setPaginationData(pagi);
            })
            .catch((e) => {});
    };

    return (
        <Fragment>
            <div>
                <Card>
                    <Card.Header>
                        <form className="w-100" onSubmit={handleSubmit(() => handleFetchData(defaultPaginationRequest))}>
                            <div className="row">
                                <CompanySelect md={3} name="companyId" control={control} required={false} disable={false} readOnly={false} onCompanyChange={setCompanyId} />
                                <ContractorSelect md={3} name="contractorId" control={control} required={false} readOnly={false} />
                                <SelectField label={t(Translations.common.status)} options={reportStatus} name="status" control={control} isMutiple={false} unSelectValue={''} md={3} />
                                <UserSelectField md={3} readOnly={false} label={t(Translations.common.pic)} name="pICId" control={control} required={false} isMutiple={false} />

                                <DateTimeField label={lang.text(Translations.common.fromDate)} md={3} control={control} readOnly={false} name={'fromDate'} defaultDate={defaultFromDate} />
                                <DateTimeField label={lang.text(Translations.common.toDate)} md={3} control={control} readOnly={false} name={'toDate'} defaultDate={defaultToDate} />
                                <SKPIObjectTypeSelect companyId={companyId} md={3} control={control} onTargetObjectNameChanged={() => {}} requiredObjectId={false} />

                                <div className="col-md-12">
                                    <FilterButton type="submit" className="mx-2 mt-3" />
                                </div>
                            </div>
                        </form>
                    </Card.Header>
                    <Card.Body>
                        <PaginationTable
                            onSearch={handleFetchData}
                            onPaginate={handleFetchData}
                            onRowClick={(item) => {
                                setCurrentDetailId(item.id);
                            }}
                            columns={cols}
                            pagination={paginationData}
                            isStriped={true}
                            disableSearch={true}
                            wrapperClass="mt-5"
                        />
                        <ModalFullScreen
                            title={lang.text(Translations.common.reportDetail)}
                            bodyModal={
                                <SKPIDetailPage
                                    onDeleted={() => {
                                        handleFetchData(defaultPaginationRequest);
                                        setCurrentDetailId(undefined);
                                    }}
                                    id={currentDetailId}
                                />
                            }
                            showModal={currentDetailId?.hasValue() ?? false}
                            handleCloseModal={() => {
                                setCurrentDetailId(undefined);
                            }}
                        />
                    </Card.Body>
                </Card>
            </div>
        </Fragment>
    );
};

export default SKPIListPage;
