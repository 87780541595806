import { Fragment, useEffect, useState } from 'react';
import Card from 'components/shared/Card';
import Translations from 'i18n/translation';
import { lang } from 'i18n/lang';
import { Accordion, Badge } from 'react-bootstrap';
import InputFieldSimple from 'components/FormControl/InputFieldSimple';
import { RADetailResponse } from 'api/v2/risk-assessment/model/rs.Response.model';
import raApi from 'api/v2/risk-assessment/raApi';
import { formatDateTime } from 'helper';
import { DeleteButton } from 'components/Button';
import { Empty } from 'components/shared';
import { ModalDetailProps } from 'models/modalDetailProps';
import ToastConfirm from 'components/Modal/ToastConfirm';

const RADetailPage = (props: ModalDetailProps) => {
    const [ra, setRa] = useState<RADetailResponse | undefined>(undefined);
    useEffect(() => {
        if (props.id) {
            raApi
                .getById(props.id)
                .then((res: RADetailResponse) => {
                    setRa(res);
                })
                .catch(() => {});
        }
    }, [props.id]);
    const onDelete = () => {
        if (ra?.id) {
            raApi
                .delete(ra.id)
                .then(() => props.onDeleted && props.onDeleted())
                .catch(() => {});
        }
    };
    return (
        <Fragment>
            {!ra && <Empty />}
            {ra && (
                <div>
                    <Card>
                        <Card.Body>
                            <Fragment>
                                {ra.isCanDelete && (
                                    <div className="d-flex justify-content-end">
                                        <ToastConfirm message={lang.text(Translations.modal.confirm.confirmDelete)} onConfirm={onDelete}>
                                            <DeleteButton />
                                        </ToastConfirm>
                                    </div>
                                )}
                                <div className="row">
                                    <InputFieldSimple md={6} value={ra.areaName} label={lang.text(Translations.common.name)} readOnly={false} />
                                    <InputFieldSimple md={3} value={ra.companyName} label={lang.text(Translations.common.name)} readOnly={false} />
                                    <InputFieldSimple md={3} value={ra.areaName} label={lang.text(Translations.common.name)} readOnly={false} />
                                    <div>
                                        <ul>
                                            <li>
                                                {lang.text(Translations.common.createdBy)} {ra.createdUserName}
                                            </li>
                                            <li>
                                                {lang.text(Translations.common.createdTime)} {formatDateTime(ra.createdTime)}
                                            </li>
                                            <li>
                                                <label className="me-2">{lang.text(Translations.ra.tag)} : </label>
                                                {ra.tags?.map((item, index) => (
                                                    <Badge key={index} bg="primary" className="me-1">
                                                        {item.name}
                                                    </Badge>
                                                ))}
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </Fragment>
                        </Card.Body>
                    </Card>
                    <div className="row">
                        <div className="col-md-12">
                            <Card>
                                <Card.Header className="d-flex justify-content-between">
                                    <h5 className="card-title  ">{lang.text(Translations.ra.controlMeasure)}</h5>
                                </Card.Header>
                                <Card.Body>
                                    <div className="row">
                                        <Accordion defaultActiveKey="0">
                                            {ra?.steps?.map((item, index) => (
                                                <Accordion.Item eventKey={index.toString()} className="" key={index}>
                                                    <Accordion.Header className="d-flex justify-content-between">
                                                        <div className="d-flex w-100 justify-content-between px-2">
                                                            <span>
                                                                # {item.stepNumber} {item.name}
                                                            </span>
                                                        </div>
                                                    </Accordion.Header>
                                                    <Accordion.Body>
                                                        {[
                                                            { label: lang.text(Translations.common.pic), value: item.id },
                                                            { label: lang.text(Translations.common.riskLevel), value: item.levelId },
                                                            { label: lang.text(Translations.ra.hazard), value: item.hazards },
                                                            { label: lang.text(Translations.ra.controlMeasure), value: item.content },
                                                        ].map((item, index) => (
                                                            <div key={index} className="d-flex">
                                                                <b style={{ minWidth: '25%' }}>{item.label} </b>
                                                                <span style={{ minWidth: '75%' }}>{item.value}</span>
                                                                <hr />
                                                            </div>
                                                        ))}
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                            ))}
                                        </Accordion>
                                    </div>
                                </Card.Body>
                            </Card>
                        </div>
                    </div>
                </div>
            )}
        </Fragment>
    );
};

export default RADetailPage;
