import userApi from 'api/v2/user/userApi';
import { SelectField, SelectFieldProps, SelectOption } from 'components/FormControl';
import _ from 'lodash';
import { useEffect, useState } from 'react';

export interface UserSelectFieldProps extends SelectFieldProps {}

export const UserSelectField: React.FC<UserSelectFieldProps> = (props: UserSelectFieldProps) => {
    const [users, setUsers] = useState<SelectOption[]>([]);
    useEffect(() => {
        userApi
            .searchDropdown('')
            .then((users) => {
                setUsers(users.toSelectOptions('label', 'value'));
            })
            .catch((n) => {});
    }, []);

    return <SelectField {...props} label={props.label} options={users} name={props.name} isMutiple={false} />;
};
export default UserSelectField;
