import client from '../client';
import { PaginationBase } from 'api/models/common';
import { LDFileRequest, LegalDocumentDetailResponse, LegalDocumentFilterRequest, LegalDocumentRequest, LegalDocumentResponse } from './legalDocument.models';

const baseURL = '/legalDocument';
const legalDocumentApi = {
    getById(id: string): Promise<LegalDocumentDetailResponse> {
        const url = `${baseURL}/getbyid/${id}`;
        return client.getNonWrap<LegalDocumentDetailResponse>({
            endpoint: url,
        });
    },
    getPagination(filter: LegalDocumentFilterRequest, isLoading: boolean = true): Promise<PaginationBase<LegalDocumentResponse>> {
        const url = `${baseURL}/GetByIndex`;
        return client.getNonWrap<PaginationBase<LegalDocumentResponse>>({
            endpoint: url,
            data: filter,
            isLoading: isLoading,
        });
    },
    create(data: LegalDocumentRequest): Promise<string> {
        const url = `${baseURL}/create`;
        return client.postNonWrap<string>({
            endpoint: url,
            data: data,
        });
    },

    update(data: LegalDocumentRequest): Promise<unknown> {
        const url = `${baseURL}/update`;
        return client.postNonWrap<unknown>({
            endpoint: url,
            data: data,
        });
    },
    delete(id: string): Promise<unknown> {
        const url = `${baseURL}/delete/${id}`;
        return client.postNonWrap<unknown>({
            endpoint: url,
        });
    },
    downloadReport(data: LegalDocumentFilterRequest): Promise<any> {
        const url = `${baseURL}/Download`;
        return client.download({
            endpoint: url,
            fileName: 'Văn bản pháp luật',
            params: data,
        });
    },
};

export default legalDocumentApi;
