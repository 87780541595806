import { RouteObject } from 'react-router-dom';

import Routers from 'router/const/routers';
import { SORCategoryListPage, SORCreatePage, SORDetailPage } from 'views/sor';
import SORDashboard from 'views/sor/report/dashboard';
import SORListPage from 'views/sor/report/sorListPage';
import SECGatePage from 'views/sec/gate';
import SECRegisterPage from 'views/sec/register';
import SECRegisterCreatePage from 'views/sec/register/createRegister';
import SECCheckInOutPage from 'views/sec/checkInOut/secCheckInOutPage';
import SECRegisterDetailPage from 'views/sec/register/detail';
import SECReportPage from 'views/sec/report/secReport';

const secRouters: RouteObject[] = [
    {
        path: Routers.sec.gate,
        element: <SECGatePage />,
    },
    {
        path: Routers.sec.register,
        element: <SECRegisterPage />,
    },
    {
        path: Routers.sec.registerCreate,
        element: <SECRegisterCreatePage   />,
    },
    {
        path: Routers.sec.checkInOut,
        element: <SECCheckInOutPage   />,
    },
    {
        path: Routers.sec.report,
        element: <SECReportPage    />,
    },
    {
        path: Routers.sec.registerDetail,
        element: <SECRegisterDetailPage    />,
    },
];

export default secRouters;
