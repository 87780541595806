import { Fragment, useEffect } from 'react';
import userApi from 'api/v2/user/userApi';
//components
import { yupResolver } from '@hookform/resolvers/yup';
import { InputField, UploadImageField } from 'components/FormControl';
import Card from 'components/shared/Card';
import Translations from 'i18n/translation';
import { SaveButton } from 'components/Button';
import { Suspense, lazy, memo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ProfileCertificateRequest, UserCreateRequest, UserDetailResponse, UserUpdateRequest } from 'api/v2/user/model';
import * as yup from 'yup';
import OrganizationUnitMultiSelect from 'components/Common/OrganizationUnitMultiSelect';
import { Col, Row, Tab, Tabs } from 'react-bootstrap';
import { Form } from 'react-bootstrap';
import { convertToSelect2Option, formatDate } from 'helper';
import { useLocation } from 'react-router-dom';
import { ActionEnum } from 'enum';
import { SelectOption, SelectField } from 'components/FormControl/SelectField';
import roleApi from 'api/v2/role/roleApi';
import { lang } from 'i18n/lang';
import DateTimeField from 'components/FormControl/DateTimeField';
import CertificationComponent from './component/certification.component';
import { EntityConst } from 'constants/EntityConst';
import { toast } from 'react-toastify';

const UserCreatePage = () => {
    const location = useLocation();

    const [idUserUpdate, setIdUserUpdate] = useState<string | null>(null);
    const [actionType, setActionType] = useState<ActionEnum>(ActionEnum.Add);
    const [rolesOption, setRolesOption] = useState<SelectOption[]>([]);
    const [defaultOrganizations, setDefaultOrganizations] = useState<string[]>([]);
    useEffect(() => {
        roleApi
            .getAllByTenant()
            .then((rs) => {
                setRolesOption(
                    convertToSelect2Option({
                        datas: rs,
                        valueProp: 'id',
                        labelProp: 'name',
                    })
                );
            })
            .catch(() => {});
        tryInitDataUpdate();
    }, []);

    const schema = yup.object().shape({
        dateOfBirth: yup.string().notRequired(),
        fullName: yup.string().required().min(5).max(200),
        email: yup.string().max(100).notRequired(),
        phoneNumber: yup.string().min(10).max(EntityConst.phoneNumber).notRequired(),
        cardNumber: yup.string().max(EntityConst.phoneNumber).notRequired(),
        userName: yup
            .string()
            .required()
            .min(3)
            .max(256)
            .test('no-spaces', 'Username should not contain spaces', (value) => !value.includes(' ')),
        password: actionType == ActionEnum.Add ? yup.string().required().min(6).max(30) : yup.string().notRequired(),
        title: yup.string().notRequired(),
        roleId: yup.string().required(),
        avatar: yup.string().notRequired(),
        organizationUnits: yup.array().required(),
    });
    const tryInitDataUpdate = async () => {
        const queryParams = new URLSearchParams(location.search);
        const isUpdate = queryParams.get('type') === ActionEnum.Update.toString();
        setActionType(isUpdate ? ActionEnum.Update : ActionEnum.Add);
        const id = queryParams.get('id');
        setIdUserUpdate(id);
        if (isUpdate && id) {
            var userData: UserDetailResponse = await userApi.getById(id);
            if (userData) {
                var orgIds = userData.organizationUnits?.map((n) => n.id ?? []) ?? [];
                reset({
                    ...userData,
                    fullName: userData.fullName,
                    email: userData.email,
                    phoneNumber: userData.phoneNumber,
                    userName: userData.userName,
                    title: userData.title,
                    roleId: userData.roleId,
                    avatar: userData.avatar,
                    organizationUnits: orgIds,
                });

                setDefaultOrganizations(orgIds);
            }
        }
    };

    const { control, handleSubmit, getValues, reset } = useForm({
        defaultValues: {
            fullName: '',
            email: '',
            phoneNumber: '',
            userName: '',
            password: '',
            title: '',
            roleId: '',
            avatar: '',
            organizationUnits: [],
        },
        resolver: yupResolver(schema),
    });

    const handleConfirmSubmit = (e: any) => {
        actionType == ActionEnum.Add ? submitAdd() : submitUpdate();
    };
    const submitAdd = () => {
        const form = getValues();
        
        const user: UserCreateRequest = {
            ...form,
            dateOfBirth: form.dateOfBirth ? formatDate(new Date(form.dateOfBirth)) : undefined,
            fullName: form.fullName,
            email: form.email ?? undefined,
            phoneNumber: form.phoneNumber ?? undefined,
            userName: form.userName,
            password: form.password ?? '',
            organizationUnitIds: form.organizationUnits,
            title: form.title ?? undefined,
            roleId: form.roleId,
            avatar: form.avatar ?? undefined,
            cardNumber: form.cardNumber ?? undefined,
        };
        userApi
            .create(user)
            .then(() => {
                reset();
            })
            .catch(() => {});
    };
    const submitUpdate = () => {
        const form = getValues();
        const user: UserUpdateRequest = {
            ...form,
            dateOfBirth: form.dateOfBirth ? formatDate(new Date(form.dateOfBirth)) : undefined,
            fullName: form.fullName,
            email: form.email ?? undefined,
            phoneNumber: form.phoneNumber ?? undefined,
            userName: form.userName,
            organizationUnitIds: form.organizationUnits,
            title: form.title ?? undefined,
            roleId: form.roleId,
            avatar: form.avatar ?? undefined,
            id: idUserUpdate ?? '',
            password: '',
            cardNumber: form.cardNumber ?? undefined,
        };
        userApi.update(user).catch(() => {});
    };

    return (
        <Fragment>
            <Form onSubmit={handleSubmit(handleConfirmSubmit)}>
                <Row>
                    {/* Left column */}
                    <Col xl="3" lg="4" className="">
                        <Card>
                            <Card.Header className="d-flex justify-content-between">
                                <div className="header-title">{/* <h4 className="card-title">Add New User</h4> */}</div>
                            </Card.Header>
                            <Card.Body>
                                <div className="profile-img-edit position-relative mb-2">
                                    <UploadImageField control={control} btnText={lang.text(Translations.common.upload)} name="avatar" className="" />
                                </div>

                                <SelectField label={lang.text(Translations.common.role)} options={rolesOption ?? []} name="roleId" control={control} required={true} md={12} />

                                <OrganizationUnitMultiSelect
                                    name="organizationUnits"
                                    control={control}
                                    isSingleCompany={false}
                                    isSingleArea={false}
                                    isShowAreaSelect={true}
                                    requiredCompany={true}
                                    requiredArea={true}
                                    md={12}
                                />
                            </Card.Body>
                        </Card>
                    </Col>

                    {/* Right column */}
                    <Col xl="9" lg="8">
                        {/* General info */}
                        <div className="row">
                            <Card>
                                <Card.Header className="">
                                    <div className="d-flex justify-content-between w-100">
                                        <h5 className="card-title ">{lang.text(Translations.common.generalInfo)}</h5>

                                        <SaveButton type="submit" className="d-flex align-items-end gap-1 " />
                                    </div>
                                </Card.Header>
                                <Card.Body>
                                    <div className="row">
                                        <InputField md={4} required={true} name="fullName" control={control} label={lang.text(Translations.form.fullName.name)} placeholder="" type="text" />
                                        <InputField md={4} required={false} name="email" control={control} label={lang.text(Translations.form.email.name)} placeholder="" type="email" />
                                        <InputField md={4} required={false} name="phoneNumber" control={control} label={lang.text(Translations.form.phone.name)} placeholder="" type="text" />
                                        <InputField md={4} required={false} name="title" control={control} label={lang.text(Translations.form.title.name)} placeholder="" />
                                        <InputField md={4} required={false} name="employeeCode" control={control} label={lang.text(Translations.userProfile.employeeCode)} placeholder="" />
                                        <DateTimeField md={4} required={false} name="dateOfBirth" control={control} label={lang.text(Translations.userProfile.dateOfBirth)} placeholder="" />
                                        <InputField md={8} required={false} name="permanentAddress" control={control} label={lang.text(Translations.userProfile.permanentAddress)} placeholder="" />
                                        <InputField
                                            md={4}
                                            required={false}
                                            name="emergencyPhoneNumber"
                                            control={control}
                                            label={lang.text(Translations.userProfile.emergencyPhoneNumber)}
                                            placeholder=""
                                        />
                                        <InputField md={4} required={false} name="cardNumber" control={control} label={lang.text(Translations.sec.dkshNumber)} placeholder="" />

                                        <InputField md={4} required={true} name="userName" control={control} label={lang.text(Translations.form.username.name)} placeholder="" />
                                        <InputField
                                            md={4}
                                            disabled={actionType != ActionEnum.Add}
                                            required={true}
                                            name="password"
                                            control={control}
                                            label={lang.text(Translations.form.password.name)}
                                            placeholder="******"
                                            type="password"
                                        />
                                    </div>
                                </Card.Body>
                            </Card>
                        </div>
                    </Col>
                </Row>
            </Form>
        </Fragment>
    );
};

export default UserCreatePage;
