import React, { CSSProperties, useEffect, useMemo, useRef } from 'react';
import { Button, Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Control, useController } from 'react-hook-form';
import fileApi from 'api/fileApi';
import { lang } from 'i18n/lang';
import Translations from 'i18n/translation';
import { generateGUID } from 'helper';
import { FileResponse } from 'api/models';
import { FileType } from 'enum/file';

export type UploadFileFieldProps = {
    defaultFilePath?: string;
    defaultFileName?: string;
    name: string;
    text?: string;
    control: Control<any>;
    className?: string;
    altText?: string;
    btnText?: string;
    src?: string | null;
    readOnly?: boolean;
    onUploaded?: (value: FileResponse) => void;
    onValueChange?: (value: string) => void;
    accepts?: string;
    md?: 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
    style?: CSSProperties;
};

export function UploadFileField({
    defaultFileName,
    defaultFilePath,
    name,
    control,
    onValueChange,
    className = '',
    md = 4,
    btnText,
    text,
    src,
    readOnly = false,
    onUploaded,
    accepts = '.doc, .docx, .xls, .xlsx, .pdf, .ppt, .pptx, .jpg, .png, .heic',
}: UploadFileFieldProps) {
    const {
        field: { value, onChange, onBlur },
        fieldState: { invalid, error },
    } = useController({
        name,
        control,
    });
    const fileInputRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        if (defaultFilePath != value) {
            onChange(defaultFilePath);
            onValueChange && onValueChange(defaultFilePath ?? value);
            onUploaded &&
                onUploaded({
                    fileName: defaultFileName ?? defaultFileName ?? '',
                    filePath: defaultFilePath ?? '',
                    size: 0,
                    extension: '',
                    fileType: FileType.None,
                });
        }
        if (defaultFilePath != value) onChange(defaultFilePath);
    }, [defaultFileName, defaultFilePath]);

    const inValidClass = error?.message ? 'is-invalid' : '';
    const id = useMemo(() => generateGUID(), []);
    const handleUploadFile = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        console.log('handleUploadFile', file);
        if (file) {
            const formData = new FormData();
            formData.append('file', file);
            fileApi
                .uploadFile(formData)
                .then((res) => {
                    if (res) {
                        onChange(res.filePath);
                        onUploaded?.(res);
                        onValueChange && onValueChange(res.filePath);
                    }
                })
                .catch((err) => {});
            // Use the formData object for further processing
            // Perform upload logic here
        }
    };

    return (
        <div className={`${className} text-start col-md-${md}`}>
            {text && (
                <Form.Label className="" htmlFor={id}>
                    {text ?? lang.text(Translations.common.upload)}
                </Form.Label>
            )}
            <Form.Control ref={fileInputRef} name={name} accept={accepts} onChange={handleUploadFile} type="file" id={id} style={{ minWidth: '266px' }} className={` ${inValidClass}`} />
            <div className="invalid-feedback">{error?.message}</div>
        </div>
    );
}

export default UploadFileField;
// readOnly={readOnly}
// type={'text'}
// placeholder={placeholderProp}
// value={value}
// onChange={onChange}
// onBlur={onBlur}
// className={`${inValidClass} ${formControlClasses}`}
// disabled={disabled}
