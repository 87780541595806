import { Button } from 'react-bootstrap';
import { BaseButtonProps } from './BaseButton.props';
interface DefaultButtonProps extends BaseButtonProps {
    text: string;
}
export function DefaultButton(props: DefaultButtonProps) {
    return (
        <Button {...props} color="info" size="sm">
            {props.text}
        </Button>
    );
}
