const Routers = {
    root: '/',
    auth: {
        login: '/sign-in',
        changePassword: '/change-password',
    },
    legalDocument:{
        create:'/legaldocument/create',
        detail:'/legaldocument/:id',
        root:'/legaldocument',
        group:'/legaldocument/group'
    },
    sec: {
        gate: '/sec/gate',
        register: '/sec/register',
        registerCreate: '/sec/register/create',
        registerDetail: '/sec/register/:id',
        // registerCreate: '/sec/register',
        report: '/sec/report',
        reportDetail: '/sec/report/detail',
        checkInOut: '/sec/checkInOut',
    },
    user: {
        root: '/user',
        action: '/user/action',
        profile: '/user/profile',
    },

    dashboard: {
        root: '/dashboard',
    },

    sor: {
        root: '/sor',
        create: '/sor/create',
        category: '/sor/category',
        detail: '/sor/detail',
        dashboard: '/sor/dashboard',
    },

    safetyInspections: {
        template: {
            root: 'safety-inspections/template',
        },
        report: {
            root: '/safety-inspections',
            create: '/safety-inspections/create',
            detail: '/safety-inspections/detail',
            dashboard: '/safety-inspections/dashboard',
            unapprovedReport: '/safety-inspections/unapprove',
            group: '/safety-inspections/group',
        },
    },

    equipment: {
        root: '/equipment/',
        detail: '/equipment/detail/:id',
        list: '/equipment/list',
        type: '/equipment/type',
        create: '/equipment/create',
        modify: '/equipment/modify/:id',
        report: '/equipment/report',
        reportDetail: '/equipment/report/:id',
        reportCreate: '/equipment/report/create',
        reportCreateDetail: '/equipment/report/create/:equipmentId/:reportType',
        checkList: '/equipment/check-list',
        checkListDetail: '/equipment/check-list/:id',
    },

    trip: {
        root: '/trip',
        trip: '/trip',
        report: '/trip/report',
    },

    kpi: {
        root: '/kpi',
        create: '/kpi/create',
        report: '/kpi/report',
        assignment: '/kpi/assignment',
        type: '/kpi/type',
    },

    wpr: {
        root: '/wpr/ppr',
        pprCreate: '/wpr/ppr/create',
        pprDetail: '/wpr/ppr/details',
        levelPPR: '/wpr/ppr/level',
        skpiCreate: '/wpr/skpi/create',
        skpiReview: '/wpr/skpi/review',
    },

    trainingOnline: {
        root: '/training-online/',
        course: '/training-online/course',
        courseCreate: '/training-online/course/create',
        scores: '/training-online/scores',
        myCourse: 'https://training.hsevn.com/login',
        category: '/training-online/category',
    },

    riskAssessment: {
        root: '/risk-assessment',
        register: '/risk-assessment/create',
        onlineDataBase: '/risk-assessment',
    },

    incidentReport: {
        root: '/incident-report',
        report: '/incident-report',
        create: '/incident-report/create',
        config: '/incident-report/config',
    },

    message: {
        root: '/message/',
        create: '/message/create',
        manager: '/messsage/manager',
    },

    action: {
        root: '/action',
    },

    library: {
        root: '/library',
    },

    customer: '/customer',
    config: {
        root: '/config/',
        dashboard: '/config',
        //
        // account: '/config/account',
        // accountCreate: '/config/account/create',
        // accountEdit: '/config/account/edit/:id',
        //
        area: '/config/area',
        areaCreate: '/config/area/create',
        areaEdit: '/config/area/edit/:id',
        //
        department: '/config/department',
        //
        riskLevel: '/config/risk-level',
        riskLevelCreate: '/config/risk-level/create',
        riskLevelUpdate: '/config/risk-level/edit/:id',
        //
        goldenRules: '/config/golden-rules',
        goldenRulesCreate: '/config/golden-rules/create',
        goldenRulesUpdate: '/config/golden-rules/edit/:id',
        //
        company: '/config/company',
        companyCreate: '/config/company/create',
        companyEdit: '/config/company/edit/:id',
        //
        contractor: '/config/contractor',
        contractorCreate: '/config/contractor/create',
        contractorEdit: '/config/contractor/edit/:id',
        //
        user: '/config/user',
        site: '/config/site',
        // role-manager
        role: '/config/role-manager',
    },

    hotline: {
        root: '/hotline',
    },
};

export default Routers;
