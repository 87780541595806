import { UserCreateRequest, UserDetailResponse, UserFilterRequest, UserResponse, UserUpdateRequest } from 'api/v2/user/model';
import { ResponseApi } from '../../models';
import client from '../client';
import { PaginationBase } from 'api/models/common';
import { SelectOption } from 'components/FormControl';

const baseUserApi = '/user';
const userApi = {
    create(data: UserCreateRequest) {
        const url = `${baseUserApi}/create`;
        return client.postNonWrap({
            endpoint: url,
            data: data,
            isLoading: true,
            isShowError: true,
            isShowSuccess: true,
        });
    },
    update(data: UserUpdateRequest): Promise<unknown> {
        const url = `${baseUserApi}/update`;
        return client.postNonWrap({
            endpoint: url,
            data: data,
            isLoading: true,
            isShowError: true,
            isShowSuccess: true,
        });
    },
    async delete(id: string): Promise<unknown> {
        const url = `${baseUserApi}/delete/${id}`;
        return client
            .postNonWrap({
                endpoint: url,
                data: true,
                isLoading: true,
                isShowError: true,
                isShowSuccess: true,
            })
            .catch(() => {});
    },

    async resetPassword(id: string): Promise<unknown> {
        const url = `${baseUserApi}/resetPassword/${id}`;
        return client
            .postNonWrap({
                endpoint: url,
                data: true,
                isLoading: true,
                isShowError: true,
                isShowSuccess: true,
            })
            .catch(() => {});
    },
    async getById(id: string) {
        const url = `${baseUserApi}/getbyid/${id}`;
        return await client.getNonWrap<UserDetailResponse>({
            endpoint: url,
            isLoading: true,
            isShowError: true,
            isShowSuccess: true,
        });
    },
    getPagination: (filter: UserFilterRequest) => {
        const url = `${baseUserApi}/getbyindex`;
        return client.getNonWrap<PaginationBase<UserResponse>>({
            endpoint: url,
            data: filter,
            isLoading: true,
            isShowError: true,
            isShowSuccess: false,
        });
    },

    getProfile: () => {
        const url = `${baseUserApi}/profile`;
        return client.getNonWrap<UserDetailResponse>({
            endpoint: url,
            isLoading: true,
            isShowError: true,
            isShowSuccess: false,
        });
    },
    changePassword(data: any) {
        const url = `${baseUserApi}/changepass`;
        return client.postNonWrap({
            endpoint: url,
            data: data,
            isLoading: true,
            isShowError: true,
            isShowSuccess: true,
        });
    },

    getManagerUserEquipment(companyId: number, areaId: number): Promise<ResponseApi<UserResponse[]>> {
        const url = `${baseUserApi}/ManagerUserEquipment/${companyId}/${areaId}`;
        return client.get(url);
    },

    searchDropdown(key: string): Promise<SelectOption[]> {
        const url = `${baseUserApi}/search/dropdown?keySearch=${key}`;
        return client.getNonWrap({
            endpoint: url,
            isLoading: false,
            isShowError: false,
            isShowSuccess: false,
        });
    },
};

export default userApi;
