import client from '../client';
import { PaginationBase } from 'api/models/common';
import { SKPIDetailResponse, SKPIResponse } from './model/skpiResponse.model';
import { SKPICloseObjectivesRequest, SKPICloseRequest, SKPIFilterRequest, SKPIRequest } from './model/skpiRequest.model';

const baseURL = '/skpi';
const skpiReportApi = {
    getById(id: string): Promise<SKPIDetailResponse> {
        const url = `${baseURL}/getbyid/${id}`;
        return client.getNonWrap<SKPIDetailResponse>({
            endpoint: url,
        });
    },
    getPagination(filter: SKPIFilterRequest, isLoading: boolean = true): Promise<PaginationBase<SKPIResponse>> {
        const url = `${baseURL}/GetByIndex`;
        return client.getNonWrap<PaginationBase<SKPIResponse>>({
            endpoint: url,
            data: filter,
            isLoading: isLoading,
        });
    },
    create(data: SKPIRequest): Promise<string> {
        const url = `${baseURL}/create`;
        return client.postNonWrap<string>({
            endpoint: url,
            data: data,
        });
    },

    update(data: SKPIRequest): Promise<unknown> {
        const url = `${baseURL}/update`;
        return client.postNonWrap<unknown>({
            endpoint: url,
            data: data,
        });
    },
    delete(id: string): Promise<unknown> {
        const url = `${baseURL}/delete/${id}`;
        return client.postNonWrap<unknown>({
            endpoint: url,
        });
    },

    closeSkpi(payload: SKPICloseRequest): Promise<unknown> {
        const url = `${baseURL}/close`;
        return client.postNonWrap<unknown>({
            endpoint: url,
            data: payload,
        });
    },
    closeItem(payload: SKPICloseObjectivesRequest): Promise<unknown> {
        const url = `${baseURL}/close/item`;
        return client.postNonWrap<unknown>({
            endpoint: url,
            data: payload,
        });
    },
    // closeSReport(payload: PPRReportCloseRequest): Promise<unknown> {
    //     const url = `${baseURL}/close`;
    //     return client.postNonWrap<unknown>({
    //         endpoint: url,
    //         data: payload,
    //     });
    // },
};

export default skpiReportApi;
