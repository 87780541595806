import { SelectOption } from 'components/FormControl';
import client from '../client';
import { SITemplateFilterRequest, SITemplateModel } from './model/siTemplate';
import { PaginationBase, PaginationBaseRequest } from 'api/models/common';
import { SIReportType } from 'enum/safetyInspection';

const templateUrl = '/SITemplate';
const siTemplateApi = {
    // template
    createTemplate(data: SITemplateModel): Promise<string> {
        const url = `${templateUrl}/create`;
        return client.postNonWrap<string>({
            endpoint: url,
            data: data,
        });
    },
    updateTemplate(data: SITemplateModel): Promise<unknown> {
        const url = `${templateUrl}/update`;
        return client.postNonWrap<string>({
            endpoint: url,
            data: data,
        });
    },
    deleteTemplate(id: string): Promise<unknown> {
        const url = `${templateUrl}/delete/${id}`;
        return client.postNonWrap<string>({
            endpoint: url,
        });
    },
    getTemplateById(id: string): Promise<SITemplateModel> {
        const url = `${templateUrl}/getbyid/${id}`;
        return client.getNonWrap<SITemplateModel>({
            endpoint: url,
        });
    },
    getTemplatePagination(
        filter: SITemplateFilterRequest
    ): Promise<PaginationBase<SITemplateModel>> {
        const url = `${templateUrl}/GetByIndex`;
        return client.getNonWrap<PaginationBase<SITemplateModel>>({
            endpoint: url,
            data: filter,
        });
    },

    getTemplateDropdown(
        reportType: SIReportType | null,
        companyId: string,
        isLoading: boolean
    ): Promise<SelectOption[]> {
        const url = `${templateUrl}/report-Dropdown/company/${companyId}/reportType=${reportType}`;
        return client.getNonWrap<SelectOption[]>({
            endpoint: url,
            isLoading: isLoading,
        });
    },
};

export default siTemplateApi;
