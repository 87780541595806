import { RouteObject } from 'react-router-dom';

import Routers from 'router/const/routers';
import { IIRConfigPage, IIRCreatePage, IIRReportPage } from 'views/incident-report';

const iirRouters: RouteObject[] = [
  {
    path: Routers.incidentReport.create,
    element: <IIRCreatePage />,
  },
  {
    path: Routers.incidentReport.config,
    element: <IIRConfigPage />,
  },
  {
    path: Routers.incidentReport.report,
    element: <IIRReportPage />,
  },
];

export default iirRouters;
