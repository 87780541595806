import { Suspense, lazy, Fragment, useEffect, useState } from 'react';

import equipmentApi from 'api/v2/equipmentApi';
import { Row, Col, Table, Button, Badge, Accordion, Modal, ListGroup } from 'react-bootstrap';
import Card from 'components/shared/Card';
import { useTranslation } from 'react-i18next';
import Translations from 'i18n/translation';
import { useParams } from 'react-router-dom';
import { ActionButtons2 } from 'components/shared/ActionButtons';
import { EquipmentReportTemplateGroupResponse, EquipmentReportTemplateItemResponse, EquipmentReportTemplateResponse } from 'api/models/equipments';
import { AddButton, DeleteButton, UpdateButton } from 'components/Button';
import usePromptInputText from 'components/Modal/PromptInputText';
import _ from 'lodash';
import ToastConfirm from 'components/Modal/ToastConfirm';

const EquipmentTemplateDetail = () => {
    const { t } = useTranslation();
    const { id } = useParams();
    const { openPromptInputText, PromptInputText } = usePromptInputText();
    const [templateDetail, setTemplateDetail] = useState<EquipmentReportTemplateResponse | null>(null);

    const onAddGroup = async () => {
        const text: string = await openPromptInputText({
            title: t(Translations.common.name),
            defaultValue: '',
        });
        if (!text) return;
        equipmentApi
            .createTemplateGroup({
                name: text,
                reportTemplateId: id as string,
            })
            .then((groupId) => {
                let group = templateDetail?.groups ?? [];
                group.push({
                    groupName: text,
                    id: groupId,
                    items: [],
                });
                var temp: any = {
                    ...templateDetail,
                    groups: group,
                };
                setTemplateDetail(temp);
            })
            .catch((n) => {});
    };
    const handleFetchData = () => {};
    const onDeleteGroup = async (group: EquipmentReportTemplateGroupResponse) => {
        equipmentApi
            .deleteTemplateGroup(group.id)
            .then(() => {
                let groups = templateDetail?.groups?.filter((g) => g.id != group.id) ?? [];

                var temp: any = {
                    ...templateDetail,
                    groups: groups,
                };
                setTemplateDetail(temp);
            })
            .catch((n) => {});
    };
    const onUpdateGroup = async (group: EquipmentReportTemplateGroupResponse) => {
        const text: string = await openPromptInputText({
            title: t(Translations.common.name),
            defaultValue: group.groupName,
        });
        if (!text) return;
        equipmentApi
            .updateTemplateGroup({
                name: text,
                id: group.id,
            })
            .then(() => {
                group.groupName = text;
                var temp: any = {
                    ...templateDetail,
                };
                setTemplateDetail(temp);
            })
            .catch((n) => {});
    };

    const onAddItem = async (group: EquipmentReportTemplateGroupResponse) => {
        const text: string = await openPromptInputText({
            title: t(Translations.common.name),
            defaultValue: '',
        });
        if (!text) return;
        equipmentApi
            .createTemplateItem({
                itemName: text,
                groupTemplateId: group.id as string,
            })
            .then((itemId) => {
                let item = {
                    itemName: text,
                    id: itemId,
                    groupTemplateId: group.id,
                };
                if (!group.items) group.items = [];
                group.items.push(item);

                var temp = { ...templateDetail };
                temp?.groups?.map((g) => {
                    if (g.id == group.id) {
                        g.items = group.items;
                        return;
                    }
                });
                setTemplateDetail(temp as EquipmentReportTemplateResponse);
            })
            .catch((n) => {});
    };

    const onDeleteItem = async (group: EquipmentReportTemplateGroupResponse, item: EquipmentReportTemplateItemResponse) => {
        equipmentApi
            .deleteTemplateItem(item.id)
            .then(() => {
                group.items = group.items?.filter((n) => n.id != item.id) ?? [];
                // var gr = group.items.filter((n) => n.id != item.id);
                // templateDetail.groups = templateDetail?.groups?.filter(n=>n.id != group.id)
                var temp = {
                    ...templateDetail,
                };
                setTemplateDetail(temp as EquipmentReportTemplateResponse);
            })
            .catch((n) => {});
    };
    const onUpdateItem = async (item: EquipmentReportTemplateItemResponse) => {
        const text: string = await openPromptInputText({
            title: t(Translations.common.name),
            defaultValue: item.itemName,
        });
        if (!text) return;
        equipmentApi
            .updateTemplateItem({
                itemName: text,
                id: item.id,
            })
            .then(() => {
                item.itemName = text;
                var temp: any = {
                    ...templateDetail,
                };
                setTemplateDetail(temp);
            })
            .catch((n) => {});
    };
    useEffect(() => {
        id &&
            equipmentApi
                .getTemplateById(id)
                .then((temp) => setTemplateDetail(temp))
                .catch(() => {});
    }, [id]);

    return (
        <Fragment>
            <Row>
                <Col sm="12">
                    <Card>
                        <Card.Body>
                            <h4 className="text-primary">{templateDetail?.templateName}</h4>
                            <Row>
                                <Col md={6}>
                                    <ListGroup as="ul">
                                        <ListGroup.Item as="li" variant="info" aria-current="true">
                                            {t(Translations.form.site.name)}
                                        </ListGroup.Item>

                                        {templateDetail?.companies?.map((company) => (
                                            <ListGroup.Item as="li" aria-current="true">
                                                {company.name}
                                            </ListGroup.Item>
                                        ))}
                                    </ListGroup>
                                </Col>
                                <Col md={6}>
                                    <ListGroup as="ul">
                                        <ListGroup.Item as="li" variant="info" aria-current="true">
                                            {t(Translations.form.site.name)}
                                        </ListGroup.Item>
                                        {templateDetail?.typeGroups?.map((company) => (
                                            <ListGroup.Item as="li" aria-current="true">
                                                {company.typeGroupName}
                                            </ListGroup.Item>
                                        ))}
                                    </ListGroup>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            <Row>
                {templateDetail && (
                    <Col sm="12">
                        <Card>
                            <Card.Body>
                                <div className="d-flex justify-content-between flex-wrap media-support-info">
                                    <h4>{t(Translations.equipment.templateCategories)}</h4>
                                    <div className="d-flex align-items-center gap-3">
                                        <AddButton isRender={true} onClick={onAddGroup} />
                                    </div>
                                </div>

                                {templateDetail.groups && (
                                    <Accordion>
                                        {templateDetail.groups.map((group, index) => (
                                            <Accordion.Item eventKey={String(index)} key={group.id}>
                                                <Accordion.Header>
                                                    {group.groupName} - {group?.items ? group?.items?.length : 0} {t(Translations.equipment.templateGroupItem)}
                                                </Accordion.Header>
                                                <Accordion.Body className="table-responsive">
                                                    <Table className="table-striped table-sm">
                                                        <tbody>
                                                            <tr>
                                                                <td>
                                                                    <div className="d-flex justify-content-start gap-2">
                                                                        <AddButton isRender={true} onClick={() => onAddItem(group)} />
                                                                        <UpdateButton onClick={() => onUpdateGroup(group)} />
                                                                        <ToastConfirm onConfirm={() => onDeleteGroup(group)}>
                                                                            <DeleteButton />
                                                                        </ToastConfirm>
                                                                    </div>
                                                                </td>
                                                                <td></td>
                                                            </tr>
                                                            {group.items &&
                                                                group.items.map((item) => (
                                                                    <tr key={item.id}>
                                                                        <td>{item.itemName} </td>
                                                                        <td>
                                                                            <div>
                                                                                <ActionButtons2
                                                                                    id={item.id}
                                                                                    editAction={() => onUpdateItem(item)}
                                                                                    onDelete={() => onDeleteItem(group, item)}
                                                                                    onDeleteSuccess={() => {}}
                                                                                />
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                ))}
                                                        </tbody>
                                                    </Table>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                        ))}
                                    </Accordion>
                                )}
                            </Card.Body>
                        </Card>
                    </Col>
                )}
            </Row>
            {PromptInputText}
        </Fragment>
    );
};

export default EquipmentTemplateDetail;
