import { RouteObject } from 'react-router-dom';

import Routers from 'router/const/routers';
import {
  TrainingCategoryPage,
  TrainingCoursesCreatePage,
  TrainingCoursesPage,
  TrainingScoresPage,
} from 'views/training';

const trainingRouters: RouteObject[] = [
  {
    path: Routers.trainingOnline.course,
    element: <TrainingCoursesPage />,
  },
  {
    path: Routers.trainingOnline.scores,
    element: <TrainingScoresPage />,
  },
  {
    path: Routers.trainingOnline.courseCreate,
    element: <TrainingCoursesCreatePage />,
  },
  {
    path: Routers.trainingOnline.category,
    element: <TrainingCategoryPage />,
  },
];

export default trainingRouters;
